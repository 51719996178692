.toggles{
	display: flex;
	flex-direction: column;
}

.toggle{
	display: flex;
	flex-direction: column;
	border:1px solid rgba(#4688f1,0.1);	
	box-shadow: 0px 0px 18px 0px rgba(70, 136, 241, 0);
	transition:.15s;
	border-radius: 8px;
	padding: 18px;
	background-color: #fff;
	&--active{
		box-shadow: 0px 0px 18px 0px rgba(70, 136, 241, 0.1);
		
		.toggle__title{
			color: #0e233c;
			&:before{
				transform: rotate(-180deg);
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='8px'%3E%3Cpath fill-rule='evenodd' fill='%230e233c' d='M10.590,0.437 L6.000,4.818 L1.410,0.437 L0.000,1.786 L6.000,7.524 L12.000,1.786 L10.590,0.437 Z'/%3E%3C/svg%3E");
			}
		}
	}
	+ .toggle{
		margin-top: 9px;
	}
	&__top{
		cursor: pointer;
	}
	&__title{
		position: relative;
		padding-left: 21px;
		font-weight: bold;
		color: #4688f1;
		//@include text-crop();
		&:before{
			content:'';
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='8px'%3E%3Cpath fill-rule='evenodd' fill='rgb(70, 136, 241)' d='M10.590,0.437 L6.000,4.818 L1.410,0.437 L0.000,1.786 L6.000,7.524 L12.000,1.786 L10.590,0.437 Z'/%3E%3C/svg%3E");
			width: 12px;
			height: 7px;
			position: absolute;
			left: 0;
			top: 9px;	
		}
		&:after{
			content:'';
			position: absolute;
			left: -18px;
			right: -18px;
			height: 60px;
			top: -18px;
		}
		&:hover{
			color: #1349e4;
			&:before{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='8px'%3E%3Cpath fill-rule='evenodd' fill='%231349e4' d='M10.590,0.437 L6.000,4.818 L1.410,0.437 L0.000,1.786 L6.000,7.524 L12.000,1.786 L10.590,0.437 Z'/%3E%3C/svg%3E");
			}
		}
	}
	&__content{
		display: none;
		padding-left: 21px;
		padding-top: 12px;
	}
}