input[type=checkbox],
input[type=radio] {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0
}

input[type="radio"] {
    +label {
        position: relative;
        padding-left: 27px;
        display: block;
        cursor: pointer;
        @include text-crop($line-height: 1.5);

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 1px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            border: 1px solid #dadcdf;
        }

        &:after {
            content: '';
            width: 6px;
            height: 6px;
            background-color: #ffffff;
            border-radius: 50%;
            position: absolute;
            left: 6px;
            top: 7px;
            opacity: 0;
        }
    }

    &:checked {
        +label {
            &:before {
                background-color: #4688f1;
                border-color: #4688f1;
            }

            &:after {
                opacity: 1;
            }
        }
    }
}

input[type="checkbox"] {
    +label {
        position: relative;
        padding-left: 27px;
        display: block;
        cursor: pointer;
        min-height: 24px;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 1px;
            width: 18px;
            height: 18px;
            border: 1px solid #e8e8e8;
            border-radius: 4px;
        }

        &:after {
            content: '';
            width: 12px;
            height: 9px;
            position: absolute;
            left: 3px;
            top: 6px;
            opacity: 0;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='9.281' viewBox='0 0 12 9.281'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23fff; fill-rule: evenodd; %7D %3C/style%3E%3C/defs%3E%3Cpath id='focus-selected' class='cls-1' d='M45.177,304.167l-4.186-4.017,1.3-1.335,2.865,2.732,6.531-6.682,1.323,1.311Z' transform='translate(-41 -294.875)'/%3E%3C/svg%3E");
        }
    }

    &:checked {
        +label {
            &:before {
                background-color: var(--primary-orange);
                border-color: var(--primary-orange);
            }

            &:after {
                opacity: 1;
            }
        }
    }
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="file"],
input[type="search"],
input[type="phone"],
textarea,
select {
    width: 100%;
    display: block;
    float: left;
    padding: 8px 12px;
    font-size: 16px;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    height: 36px;
    font-weight: 300;
    outline: 0;
    border-radius: 4px;
    font-family: inherit;
    border: 1px solid #e8e8e8;
    text-overflow: ellipsis;
    font-size: 14px;

    @include placeholder {
        color: rgba(#0e233c, 0.6);
    }

    &:focus,
    .form__component--focus & {
        background-color: #fff;
        border: 1px solid var(--primary-blue);

        @include placeholder {
            color: #0e233c;
        }

        +.form__label {
            color: #4688f1;
        }

        +.toggle-password {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='12.25' viewBox='0 0 18 12.25'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %230c2f72; fill-rule: evenodd; %7D %3C/style%3E%3C/defs%3E%3Cpath id='icon-visibility' class='cls-1' d='M264,251a8,8,0,0,1,7.217,4.5,8.037,8.037,0,0,1-14.434,0A8,8,0,0,1,264,251m0-1.636a9.675,9.675,0,0,0-9,6.136,9.668,9.668,0,0,0,18,0A9.675,9.675,0,0,0,264,249.364Zm0,4.091a2.046,2.046,0,1,1-2.046,2.045A2.046,2.046,0,0,1,264,253.455m0-1.637a3.682,3.682,0,1,0,3.682,3.682A3.688,3.688,0,0,0,264,251.818Z' transform='translate(-255 -249.375)'/%3E%3C/svg%3E");

            &.toggle-password--active {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18px' height='16px'%3E%3Cpath fill-rule='evenodd' opacity='1' fill='%230c2f72' d='M9.000,2.915 C12.101,2.915 14.867,4.658 16.217,7.415 C15.734,8.413 15.055,9.272 14.245,9.968 L15.399,11.121 C16.536,10.115 17.436,8.855 18.000,7.415 C16.585,3.823 13.091,1.278 9.000,1.278 C7.961,1.278 6.963,1.442 6.022,1.745 L7.372,3.095 C7.904,2.988 8.444,2.915 9.000,2.915 ZM8.125,3.848 L9.818,5.541 C10.285,5.746 10.661,6.122 10.866,6.589 L12.559,8.282 C12.625,8.004 12.674,7.710 12.674,7.407 C12.682,5.378 11.029,3.733 9.000,3.733 C8.697,3.733 8.411,3.774 8.125,3.848 ZM0.826,1.172 L3.019,3.365 C1.686,4.412 0.630,5.803 -0.000,7.415 C1.415,11.007 4.909,13.551 9.000,13.551 C10.244,13.551 11.438,13.314 12.535,12.881 L15.333,15.679 L16.487,14.525 L1.980,0.010 L0.826,1.172 ZM6.963,7.309 L9.098,9.444 C9.066,9.452 9.033,9.460 9.000,9.460 C7.871,9.460 6.955,8.544 6.955,7.415 C6.955,7.374 6.963,7.349 6.963,7.309 ZM4.181,4.527 L5.613,5.959 C5.425,6.409 5.318,6.899 5.318,7.415 C5.318,9.444 6.971,11.097 9.000,11.097 C9.516,11.097 10.007,10.991 10.448,10.802 L11.250,11.604 C10.530,11.800 9.778,11.915 9.000,11.915 C5.899,11.915 3.134,10.172 1.784,7.415 C2.356,6.245 3.191,5.279 4.181,4.527 Z'/%3E%3C/svg%3E");
            }
        }
    }
}

select {
    padding-right: 30px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='8px'%3E%3Cpath fill-rule='evenodd' fill-opacity='0.8' fill='rgb(14, 35, 60)' d='M10.590,0.437 L6.000,4.818 L1.410,0.437 L0.000,1.786 L6.000,7.524 L12.000,1.786 L10.590,0.437 Z'/%3E%3C/svg%3E");
    background-position: right 12px center;
    background-repeat: no-repeat;

    &:focus,
    .topbar__search--focus & {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='8px'%3E%3Cpath fill-rule='evenodd' fill-opacity='1' fill='%234688f1' d='M10.590,0.437 L6.000,4.818 L1.410,0.437 L0.000,1.786 L6.000,7.524 L12.000,1.786 L10.590,0.437 Z'/%3E%3C/svg%3E");
    }

    &:not(:focus) {
        color: rgba(#0e233c, 0.6);
    }
}

textarea {
    min-height: 115px !important;
    resize: vertical;
    margin-bottom: 18px;
}

.checkbox-toggle {
    input[type="checkbox"] {
        +label {
            position: relative;
            padding-left: 39px;
            min-height: 20px;
            cursor: pointer;

            &:before {
                content: '';
                position: absolute;
                width: 30px;
                height: 6px;
                border-radius: 6px;
                left: 0;
                top: 8px;
                background-color: #cfd3d8;

            }

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 1px;
                border-radius: 50%;
                background-color: #fff;
                border: 1px solid #cfd3d8;
                width: 20px;
                height: 20px;
                transition: .1s;
                opacity: 1;
                background-image: none;
            }
        }

        &:checked {
            +label {
                &:before {
                    border: 0;
                }

                &:after {
                    left: 10px;
                    background-color: #4688f1;
                    border-color: #4688f1;
                }
            }
        }
    }
}

.form-field-info {
    font-style: italic;
    color: #777;
}

.form {
    @include typography(14px, 14px, 14px, 1.5);

    &__row {
        display: flex;
        flex-wrap: wrap;
        margin-left: -9px;
        margin-right: -9px;

        &:last-child {
            margin-bottom: -18px;
        }

        &--buttons {
            align-items: center;

            .form__component {
                width: auto;
                flex-grow: 1;
                flex-shrink: 1;
            }
        }

        &--bb {
            margin-left: -18px;
            margin-right: -18px;
            border-bottom: 1px solid #e8e8e8;
            margin-bottom: 18px;
            margin-top: 18px;
            .form__component {
                padding-left: 18px;
                padding-right: 18px;
            }
        }

        &--fieldset {
            .form__component {
                width: 100%;

                &.form__component--auto {
                    width: auto;
                    min-width: 125px;
                }
            }

            &.form__row--date {
                .form__component--auto {
                    width: 33.33%;
                }
            }
        }
    }

    &__label {
        @include text-crop();
        font-size: 14px;
        color: #7a7a7a;
        margin-top: 6px;
        margin-bottom: 6px;
        display: block;
        // min-width:300px; // #new added
        // float:left; // #new added
        font-weight: 300;

        .form__component--focus & {
            color: var(--primary-blue);
        }
    }

    &__component {
        display: flex; // #new removed
        flex-direction: column; // #new removed
        padding-left: 9px;
        padding-right: 9px;
        width: 100%;
    }

    &__element {
        display: block;
        // width: 100%; // #new removed
        padding-bottom: 18px;
        position: relative;

        // min-width:300px; // #new added
        // .form__element { // #new added
        //     min-width:50px; ///#new added
        // }
        &--error {
            .form__label {
                color: #fc0e3d;
            }

            input,
            select {
                border-color: #fc0e3d;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='40px' height='40px'%3E%3Cdefs%3E%3Cfilter id='Filter_0'%3E%3CfeFlood flood-color='rgb(252, 14, 61)' flood-opacity='0.1' result='floodOut' /%3E%3CfeComposite operator='atop' in='floodOut' in2='SourceGraphic' result='compOut' /%3E%3CfeBlend mode='normal' in='compOut' in2='SourceGraphic' /%3E%3C/filter%3E%3C/defs%3E%3Cg filter='url(%23Filter_0)'%3E%3Cpath fill-rule='evenodd' fill='rgb(255, 255, 255)' d='M4.000,0.000 L40.000,0.000 L40.000,40.000 L4.000,40.000 C1.791,40.000 -0.000,38.209 -0.000,36.000 L-0.000,4.000 C-0.000,1.791 1.791,0.000 4.000,0.000 Z'/%3E%3C/g%3E%3Cpath fill-rule='evenodd' fill='rgb(252, 14, 61)' d='M20.000,15.493 L25.648,25.250 L14.353,25.250 L20.000,15.493 M20.000,12.500 L11.750,26.750 L28.250,26.750 L20.000,12.500 ZM20.750,23.000 L19.250,23.000 L19.250,24.500 L20.750,24.500 L20.750,23.000 ZM20.750,18.500 L19.250,18.500 L19.250,21.500 L20.750,21.500 L20.750,18.500 Z'/%3E%3C/svg%3E");
                padding-left: 45px;
                background-size: 40px 40px;
                background-position: left center;
                background-repeat: no-repeat;

                @include placeholder() {
                    color: #fc0e3d;
                }
            }

            input[type="checkbox"],
            input[type="radio"] {
                +label {
                    &:before {
                        border-color: #ff0000;
                    }
                }
            }
        }

        &--password {

            input[type="text"],
            input[type="password"] {
                padding-right: 48px;
            }
        }

        &--categories {
            padding-bottom: 0px;
        }

        &--title {
            padding: 0px 9px;
            width:100% !important;
            h4 {
                padding-bottom: 6px;
                margin-bottom: 6px;
                border-bottom: solid 1px #efefef;
            }
        }
        .button {
            align-self: flex-start;
        }

        &.division {
            display: flex;
            justify-content: space-between;
        }

        .first {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 0 5px 0.5rem 0;
        }

        .first button {
            margin-top: 0.5rem;
        }
    }

    &__fieldset {
        display: flex;
        gap: 18px;
        width: 100%;
        padding-left: 9px;
        padding-right: 9px;

        &.fieldset--salutation {
            .form__container {
                flex-basis: auto;
                width: auto;

                label {
                    white-space: nowrap;
                }
            }
        }
    }

    &__input {
        display: flex;
        flex-direction: column-reverse;
    }

    &__scan {
        position: relative;
        align-items: flex-start;

        .closeQR {
            position: absolute;
            height: 40px;
            width: 40px;
            background-color: #666;
            transition: background-color 300ms ease-out;
            color: #fff;
            font-family: "BMWGlobal";
            display: flex;
            justify-content: center;
            align-items: center;
            right: 0;
            top: 0;
            z-index: 10;
            cursor: pointer;

            span {
                font-size: 23px;
                transform: rotate(45deg);
            }

            .mini-layout & {
                background-color: #000;
            }
        }
    }
}


.toggle-password {
    cursor: pointer;
    right: 9px;
    top: calc(50% - 16px);
    width: 18px;
    height: 14px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='12.25' viewBox='0 0 18 12.25'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23a6a6a6; fill-rule: evenodd; %7D %3C/style%3E%3C/defs%3E%3Cpath id='icon-visibility' class='cls-1' d='M264,251a8,8,0,0,1,7.217,4.5,8.037,8.037,0,0,1-14.434,0A8,8,0,0,1,264,251m0-1.636a9.675,9.675,0,0,0-9,6.136,9.668,9.668,0,0,0,18,0A9.675,9.675,0,0,0,264,249.364Zm0,4.091a2.046,2.046,0,1,1-2.046,2.045A2.046,2.046,0,0,1,264,253.455m0-1.637a3.682,3.682,0,1,0,3.682,3.682A3.688,3.688,0,0,0,264,251.818Z' transform='translate(-255 -249.375)'/%3E%3C/svg%3E");
    position: absolute;
    background-repeat: no-repeat;
    background-position: right center;

    &--active {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18px' height='16px'%3E%3Cpath fill-rule='evenodd' opacity='1' fill='%23a6a6a6' d='M9.000,2.915 C12.101,2.915 14.867,4.658 16.217,7.415 C15.734,8.413 15.055,9.272 14.245,9.968 L15.399,11.121 C16.536,10.115 17.436,8.855 18.000,7.415 C16.585,3.823 13.091,1.278 9.000,1.278 C7.961,1.278 6.963,1.442 6.022,1.745 L7.372,3.095 C7.904,2.988 8.444,2.915 9.000,2.915 ZM8.125,3.848 L9.818,5.541 C10.285,5.746 10.661,6.122 10.866,6.589 L12.559,8.282 C12.625,8.004 12.674,7.710 12.674,7.407 C12.682,5.378 11.029,3.733 9.000,3.733 C8.697,3.733 8.411,3.774 8.125,3.848 ZM0.826,1.172 L3.019,3.365 C1.686,4.412 0.630,5.803 -0.000,7.415 C1.415,11.007 4.909,13.551 9.000,13.551 C10.244,13.551 11.438,13.314 12.535,12.881 L15.333,15.679 L16.487,14.525 L1.980,0.010 L0.826,1.172 ZM6.963,7.309 L9.098,9.444 C9.066,9.452 9.033,9.460 9.000,9.460 C7.871,9.460 6.955,8.544 6.955,7.415 C6.955,7.374 6.963,7.349 6.963,7.309 ZM4.181,4.527 L5.613,5.959 C5.425,6.409 5.318,6.899 5.318,7.415 C5.318,9.444 6.971,11.097 9.000,11.097 C9.516,11.097 10.007,10.991 10.448,10.802 L11.250,11.604 C10.530,11.800 9.778,11.915 9.000,11.915 C5.899,11.915 3.134,10.172 1.784,7.415 C2.356,6.245 3.191,5.279 4.181,4.527 Z'/%3E%3C/svg%3E");
    }
}

input#file-upload {
    display: none;
}

input.file-upload {
    display: none;
}

.file-upload-button {
    align-items: center;
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    cursor: pointer;
    display: inline-flex;
    font-size: 16px;
    font-weight: 700;
    height: 36px;
    justify-content: center;
    padding: 6px 18px;
    margin: 10px 0px;
    transition: .15s;
    white-space: nowrap;
}
.file-upload-list {
    margin-bottom:20px;
}
.file-upload-list .file-upload {
    display: block;
    border: none;
    padding: 10px 5px 10px 40px;

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16px' height='16px'%3E%3Cpath fill-rule='evenodd' fill='%230c2f72' d='M8.000,-0.000 L6.590,1.410 L12.170,7.000 L-0.000,7.000 L-0.000,9.000 L12.170,9.000 L6.590,14.590 L8.000,16.000 L16.000,8.000 L8.000,-0.000 Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: left 12px center;
}

.file-upload-list .file-upload-error  {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='40px' height='40px'%3E%3Cdefs%3E%3Cfilter id='Filter_0'%3E%3CfeFlood flood-color='rgb(252, 14, 61)' flood-opacity='0.1' result='floodOut' /%3E%3CfeComposite operator='atop' in='floodOut' in2='SourceGraphic' result='compOut' /%3E%3CfeBlend mode='normal' in='compOut' in2='SourceGraphic' /%3E%3C/filter%3E%3C/defs%3E%3Cg filter='url(%23Filter_0)'%3E%3Cpath fill-rule='evenodd' fill='rgb(255, 255, 255)' d='M4.000,0.000 L40.000,0.000 L40.000,40.000 L4.000,40.000 C1.791,40.000 -0.000,38.209 -0.000,36.000 L-0.000,4.000 C-0.000,1.791 1.791,0.000 4.000,0.000 Z'/%3E%3C/g%3E%3Cpath fill-rule='evenodd' fill='rgb(252, 14, 61)' d='M20.000,15.493 L25.648,25.250 L14.353,25.250 L20.000,15.493 M20.000,12.500 L11.750,26.750 L28.250,26.750 L20.000,12.500 ZM20.750,23.000 L19.250,23.000 L19.250,24.500 L20.750,24.500 L20.750,23.000 ZM20.750,18.500 L19.250,18.500 L19.250,21.500 L20.750,21.500 L20.750,18.500 Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: left center;
    background-color:rgba(252, 14, 61, 0.05);
    border-radius: 8px;
    border: 1px solid rgb(252, 14, 61);
    color: rgb(252, 14, 61);
}

.categories-trail.wrapper {
    padding: 3px 0px 6px 0px;
}

.categories-trail {
    display: inline-flex;
    border: none;
    color: #777;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='12px'%3E%3Cpath fill-rule='evenodd' fill='rgb(12, 47, 114)' d='M12.000,1.209 L10.791,-0.000 L6.000,4.792 L1.208,-0.000 L-0.001,1.209 L4.791,6.000 L-0.001,10.792 L1.208,12.000 L6.000,7.209 L10.791,12.000 L12.000,10.792 L7.208,6.000 L12.000,1.209 Z'/%3E%3C/svg%3E");
    background-position: right 18px center;
    padding: 3px 40px 3px 0px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.categories-trail.selectable {
    font-style: italic;
    color: #4688f1;
    background-image: none;
}

.categories-trail:last-of-type {
    //background-image:none;
    // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='12px'%3E%3Cpath fill-rule='evenodd' fill='rgb(12, 47, 114)' d='M12.000,1.209 L10.791,-0.000 L6.000,4.792 L1.208,-0.000 L-0.001,1.209 L4.791,6.000 L-0.001,10.792 L1.208,12.000 L6.000,7.209 L10.791,12.000 L12.000,10.792 L7.208,6.000 L12.000,1.209 Z'/%3E%3C/svg%3E");
}

.css-13cymwt-control,
.css-t3ipsp-control {
    border-color: #e8e8e8 !important;

    div {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        margin: 0px !important;
    }
}

.css-1fdsijx-ValueContainer {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.form-fieldset {
    display: flex;
    width: 100%;

    .form__component {
        padding-left: 0px;
        padding-right: 0px;
    }

    .fieldset-element {

        padding-right: 10px;

        &.col-1 {
            width: calc(100%/12);
        }

        &.col-2 {
            width: calc((100%/12)*2);
        }

        &.col-3 {
            width: calc((100%/12)*3);
        }

        &.col-4 {
            width: calc((100%/12)*4);
        }

        &.col-5 {
            width: calc((100%/12)*5);
        }

        &.col-6 {
            width: calc((100%/12)*6);
        }

        &.col-7 {
            width: calc((100%/12)*7);
        }

        &.col-8 {
            width: calc((100%/12)*8);
        }

        &.col-9 {
            width: calc((100%/12)*9);
        }

        &.col-10 {
            width: calc((100%/12)*10);
        }

        &.col-11 {
            width: calc((100%/12)*11);
        }

        &.col-12 {
            width: calc((100%/12)*12);
        }
    }
}

.form__row .form__element--html {
    padding-left: 10px;
}


@media screen and (min-width:575px) {

    .form__row--buttons .form__component {
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.form__row--fieldset:not(.form__row--date) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.form__wrapper {
    .form__element {
        &--select{
            padding-bottom:6px;
        }
    }
}

@media screen and (min-width:725px) {

    .form__wrapper {

        .form__label {
            width: 300px;
            float: left;
        }

        .form__component {
            display: block; // flex
            flex-direction: row; // column
        }

        .form__element {
            min-height: 42px;
            //padding-bottom:0px;
            width: auto;

            input {
                width:calc( 100% - 300px );
            }

            .form__element {
                min-width: 50px;
            }
            
        }

        .form__row--fieldset {
            .form__element {
                input {
                    width:100%;
                }
            }
            .form__component {
                padding-left:0px;
                padding-right:0px;
            }

        }
        .form-fieldset {
            .fieldset-element:not(:first-of-type){
                .form__label {
                    width: auto;
                }
                .form__element {
                    input {
                        width:inherit;
                    }
                }
            }

        }

        .form__row--date {
            .form__component {
                width: calc((100% - 300px) /3)
            }
            .form__component:first-child {
                width: 300px;
            }
        }

    }
}

.form-fieldset label {
    padding-left: 10px;
}
