@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}


@mixin text-crop($line-height: 1.5, $top-adjustment: 0px, $bottom-adjustment: 0px) {
    // Configured in Step 1
    $top-crop: 9;
    $bottom-crop: -10;
    $crop-font-size: 36;
    $crop-line-height: 1.2;

    // Apply values to calculate em-based margins that work with any font size
    $dynamic-top-crop: max(($top-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0) / $crop-font-size;
    $dynamic-bottom-crop: max(($bottom-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0) / $crop-font-size;

    // Mixin output
    line-height: $line-height;

    &::before,
    &::after {
        content: '';
        display: block;
        height: 0;
        width: 0;
    }

    &::before {
        margin-bottom: calc(-#{$dynamic-top-crop}em + #{$top-adjustment});
    }

    &::after {
        margin-top: calc(-#{$dynamic-bottom-crop}em + #{$bottom-adjustment});
    }
}


@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}


@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size, $line-height) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size); 
	$u5: unit($line-height);
	font-size: $min-font-size;
	@media screen and (min-width: $min-vw) {
		font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
		margin-bottom: calc( ( #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}) * (#{$line-height} ) / 2) );
		margin-top: calc( ( #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}) * #{$line-height} ) / 2 );
	}
	@media screen and (min-width: $max-vw) {
		font-size: $max-font-size;
		margin-top: calc( ( #{$max-font-size} * #{$line-height} ) / 2 );
		margin-bottom: calc( ( #{$max-font-size} * #{$line-height} ) / 2 );
	}
}


@mixin typography($font-size-large,$font-size-medium,$font-size-small,$line-height) {
    
    @media screen and (min-width:$min_width){
    	font-size: $font-size-small;
    	line-height: $line-height;
    	margin-bottom: calc( ( #{$font-size-small} * #{$line-height} ) / 2 );
    	margin-top: calc( ( #{$font-size-small} * #{$line-height} ) / 2 );
    }
    
    @media screen and (min-width:991px){
    	font-size: $font-size-medium;
    	margin-bottom: calc( ( #{$font-size-medium} * #{$line-height} ) / 2 );
    	margin-top: calc( ( #{$font-size-medium} * #{$line-height} ) / 2 );
    }
    @media screen and (min-width:$max_width){
    	font-size: $font-size-large;
    	margin-bottom: calc( ( #{$font-size-large} * #{$line-height} ) / 2 );
    	margin-top: calc( ( #{$font-size-large} * #{$line-height} ) / 2 );
    }
  
}

@function url-friendly-colour($colour) {
    @return '%23' + str-slice('#{$colour}', 2, -1)
}