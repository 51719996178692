.side{
	width: 72px;
	background-color: #fff;
	border-right: 1px solid #ecf3fe;
	flex-shrink:0;
	transition:.15s;
	width: 288px;
	display: flex;
	flex-direction: column;
	//height:100vh;
	//height:calc(var(--vh, 1vh) * 100);
	min-height:calc(var(--vh, 1vh) * 100);
	/* position: fixed;
	left: 0;
	top: 0;
	bottom: 0; */
	&__top{
		height: var(--navbar-height);
		display: flex;
		align-items:center;
		padding-left: 18px;
		padding-right: 18px;
		border-bottom: 1px solid #ecf3fe;
		position: sticky;
		top: 0;
		background-color: #fff;
		z-index: 99;
		flex-shrink: 0;
	}
	
	&__center{
		padding-top: 9px;
		padding-bottom: 9px;
		//flex-grow: 1;
    	//flex-shrink: 1;
    	//overflow-y: auto;
    	position: sticky;
		top: var(--navbar-height);
		z-index: 10;
		
		&::-webkit-scrollbar {
		  width: 5px;
		}
		 
		&::-webkit-scrollbar-track {
		  background: #ffffff;
		}
		 
		&::-webkit-scrollbar-thumb {
		  background: #ecf3fe; 
		}
	}
	&__bottom{
		padding-left: 18px;
		padding-right: 18px;
		height: 50px;
		cursor: pointer;
		border-top:1px solid #ecf3fe;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18px' height='14px'%3E%3Cpath fill-rule='evenodd' fill='rgb(70, 136, 241)' d='M6.000,14.000 L7.410,12.590 L6.000,11.170 L3.830,9.000 L13.000,9.000 C15.760,9.000 18.000,6.760 18.000,4.000 L18.000,0.000 L16.000,0.000 L16.000,4.000 C16.000,5.650 14.650,7.000 13.000,7.000 L3.830,7.000 L6.000,4.830 L7.410,3.420 L6.000,2.000 L-0.000,8.000 L6.000,14.000 Z'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: left 27px center;
	    flex-shrink: 0;
	    margin-top: auto;
	    position: sticky;
	    bottom: 0;
	    z-index: 99;
	    transform:scaleX(-1);
	}
	
	&:not(.side--collapsed){
		.side__center{
		    flex-shrink: 1;
		    flex-grow: 1;
		    overflow-y: auto;
		    -webkit-overflow-scrolling: touch;
		}
		.side__bottom{
			transform:scaleX(1);
		}
	}
	&__navigation{
		&__item{
			position: relative;
			padding-right: 18px;
			padding-left: 18px;
			
			&--item1{
				> a{
					.side__navigation__item__icon{
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='16px'%3E%3Cpath fill-rule='evenodd' fill-opacity='0.6' fill='rgb(14, 35, 60)' d='M20.000,4.000 L11.000,4.000 L11.000,6.000 L20.000,6.000 L20.000,4.000 ZM20.000,12.000 L11.000,12.000 L11.000,14.000 L20.000,14.000 L20.000,12.000 ZM3.540,8.000 L-0.000,4.460 L1.410,3.050 L3.530,5.170 L7.770,0.930 L9.180,2.340 L3.540,8.000 ZM3.540,16.000 L-0.000,12.460 L1.410,11.050 L3.530,13.170 L7.770,8.930 L9.180,10.340 L3.540,16.000 Z'/%3E%3C/svg%3E");
					}
					&:hover{
						.side__navigation__item__icon{
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='16px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' d='M20.000,4.000 L11.000,4.000 L11.000,6.000 L20.000,6.000 L20.000,4.000 ZM20.000,12.000 L11.000,12.000 L11.000,14.000 L20.000,14.000 L20.000,12.000 ZM3.540,8.000 L-0.000,4.460 L1.410,3.050 L3.530,5.170 L7.770,0.930 L9.180,2.340 L3.540,8.000 ZM3.540,16.000 L-0.000,12.460 L1.410,11.050 L3.530,13.170 L7.770,8.930 L9.180,10.340 L3.540,16.000 Z'/%3E%3C/svg%3E");
						}
					}
				}
			}
			&--item2{
				> a{
					.side__navigation__item__icon{
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14px' height='20px'%3E%3Cpath fill-rule='evenodd' fill-opacity='0.6' fill='rgb(14, 35, 60)' d='M-0.000,20.000 L-0.000,18.000 L14.000,18.000 L14.000,20.000 L-0.000,20.000 ZM7.000,17.000 C2.330,13.560 -0.000,10.270 -0.000,7.150 C-0.000,2.460 3.730,-0.000 7.000,-0.000 C10.270,-0.000 14.000,2.460 14.000,7.150 C14.000,10.270 11.670,13.560 7.000,17.000 ZM7.000,2.000 C5.070,2.000 2.000,3.400 2.000,7.150 C2.000,9.300 3.720,11.820 7.000,14.470 C10.280,11.820 12.000,9.310 12.000,7.150 C12.000,3.400 8.930,2.000 7.000,2.000 ZM7.000,9.000 C5.900,9.000 5.000,8.100 5.000,7.000 C5.000,5.900 5.900,5.000 7.000,5.000 C8.105,5.000 9.000,5.895 9.000,7.000 C9.000,8.105 8.105,9.000 7.000,9.000 Z'/%3E%3C/svg%3E");
					}
					&:hover{
						.side__navigation__item__icon{
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14px' height='20px'%3E%3Cpath fill-rule='evenodd' fill-opacity='1' fill='rgb(14, 35, 60)' d='M-0.000,20.000 L-0.000,18.000 L14.000,18.000 L14.000,20.000 L-0.000,20.000 ZM7.000,17.000 C2.330,13.560 -0.000,10.270 -0.000,7.150 C-0.000,2.460 3.730,-0.000 7.000,-0.000 C10.270,-0.000 14.000,2.460 14.000,7.150 C14.000,10.270 11.670,13.560 7.000,17.000 ZM7.000,2.000 C5.070,2.000 2.000,3.400 2.000,7.150 C2.000,9.300 3.720,11.820 7.000,14.470 C10.280,11.820 12.000,9.310 12.000,7.150 C12.000,3.400 8.930,2.000 7.000,2.000 ZM7.000,9.000 C5.900,9.000 5.000,8.100 5.000,7.000 C5.000,5.900 5.900,5.000 7.000,5.000 C8.105,5.000 9.000,5.895 9.000,7.000 C9.000,8.105 8.105,9.000 7.000,9.000 Z'/%3E%3C/svg%3E");
						}
					}
				}
			}
			&--item3{
				> a{
					.side__navigation__item__icon{
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='22px' height='16px'%3E%3Cpath fill-rule='evenodd' fill-opacity='0.6' fill='rgb(14, 35, 60)' d='M18.000,16.000 L18.000,13.000 C18.000,11.320 17.040,10.060 15.670,9.130 C18.430,9.530 22.000,10.820 22.000,13.000 L22.000,16.000 L18.000,16.000 ZM14.000,8.000 C13.530,8.000 13.090,7.900 12.670,7.760 C13.500,6.730 14.000,5.420 14.000,4.000 C14.000,2.580 13.500,1.270 12.670,0.240 C13.090,0.100 13.530,0.000 14.000,0.000 C16.210,0.000 18.000,1.790 18.000,4.000 C18.000,6.210 16.210,8.000 14.000,8.000 ZM8.000,8.000 C5.790,8.000 4.000,6.210 4.000,4.000 C4.000,1.790 5.790,0.000 8.000,0.000 C10.210,0.000 12.000,1.790 12.000,4.000 C12.000,6.210 10.210,8.000 8.000,8.000 ZM8.000,2.000 C6.900,2.000 6.000,2.900 6.000,4.000 C6.000,5.100 6.900,6.000 8.000,6.000 C9.100,6.000 10.000,5.100 10.000,4.000 C10.000,2.900 9.100,2.000 8.000,2.000 ZM16.000,13.000 L16.000,16.000 L-0.000,16.000 L-0.000,13.000 C-0.000,10.340 5.330,9.000 8.000,9.000 C10.670,9.000 16.000,10.340 16.000,13.000 ZM2.000,13.010 L2.000,14.000 L14.000,14.000 L14.000,13.000 C13.800,12.290 10.700,11.000 8.000,11.000 C5.300,11.000 2.200,12.290 2.000,13.010 Z'/%3E%3C/svg%3E");
					}
					&:hover{
						.side__navigation__item__icon{
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='22px' height='16px'%3E%3Cpath fill-rule='evenodd' fill-opacity='1' fill='rgb(14, 35, 60)' d='M18.000,16.000 L18.000,13.000 C18.000,11.320 17.040,10.060 15.670,9.130 C18.430,9.530 22.000,10.820 22.000,13.000 L22.000,16.000 L18.000,16.000 ZM14.000,8.000 C13.530,8.000 13.090,7.900 12.670,7.760 C13.500,6.730 14.000,5.420 14.000,4.000 C14.000,2.580 13.500,1.270 12.670,0.240 C13.090,0.100 13.530,0.000 14.000,0.000 C16.210,0.000 18.000,1.790 18.000,4.000 C18.000,6.210 16.210,8.000 14.000,8.000 ZM8.000,8.000 C5.790,8.000 4.000,6.210 4.000,4.000 C4.000,1.790 5.790,0.000 8.000,0.000 C10.210,0.000 12.000,1.790 12.000,4.000 C12.000,6.210 10.210,8.000 8.000,8.000 ZM8.000,2.000 C6.900,2.000 6.000,2.900 6.000,4.000 C6.000,5.100 6.900,6.000 8.000,6.000 C9.100,6.000 10.000,5.100 10.000,4.000 C10.000,2.900 9.100,2.000 8.000,2.000 ZM16.000,13.000 L16.000,16.000 L-0.000,16.000 L-0.000,13.000 C-0.000,10.340 5.330,9.000 8.000,9.000 C10.670,9.000 16.000,10.340 16.000,13.000 ZM2.000,13.010 L2.000,14.000 L14.000,14.000 L14.000,13.000 C13.800,12.290 10.700,11.000 8.000,11.000 C5.300,11.000 2.200,12.290 2.000,13.010 Z'/%3E%3C/svg%3E");
						}
					}
				}
			}
			&--item4{
				> a{
					.side__navigation__item__icon{
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill-opacity='0.6' fill='rgb(14, 35, 60)' d='M2.000,4.000 L-0.000,4.000 L-0.000,18.000 C-0.000,19.100 0.900,20.000 2.000,20.000 L16.000,20.000 L16.000,18.000 L2.000,18.000 L2.000,4.000 ZM18.000,0.000 L6.000,0.000 C4.900,0.000 4.000,0.900 4.000,2.000 L4.000,14.000 C4.000,15.100 4.900,16.000 6.000,16.000 L18.000,16.000 C19.100,16.000 20.000,15.100 20.000,14.000 L20.000,2.000 C20.000,0.900 19.100,0.000 18.000,0.000 ZM18.000,14.000 L6.000,14.000 L6.000,2.000 L18.000,2.000 L18.000,14.000 ZM8.000,7.000 L16.000,7.000 L16.000,9.000 L8.000,9.000 L8.000,7.000 ZM8.000,10.000 L12.000,10.000 L12.000,12.000 L8.000,12.000 L8.000,10.000 ZM8.000,4.000 L16.000,4.000 L16.000,6.000 L8.000,6.000 L8.000,4.000 Z'/%3E%3C/svg%3E");
					}
					&:hover{
						.side__navigation__item__icon{
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill-opacity='1' fill='rgb(14, 35, 60)' d='M2.000,4.000 L-0.000,4.000 L-0.000,18.000 C-0.000,19.100 0.900,20.000 2.000,20.000 L16.000,20.000 L16.000,18.000 L2.000,18.000 L2.000,4.000 ZM18.000,0.000 L6.000,0.000 C4.900,0.000 4.000,0.900 4.000,2.000 L4.000,14.000 C4.000,15.100 4.900,16.000 6.000,16.000 L18.000,16.000 C19.100,16.000 20.000,15.100 20.000,14.000 L20.000,2.000 C20.000,0.900 19.100,0.000 18.000,0.000 ZM18.000,14.000 L6.000,14.000 L6.000,2.000 L18.000,2.000 L18.000,14.000 ZM8.000,7.000 L16.000,7.000 L16.000,9.000 L8.000,9.000 L8.000,7.000 ZM8.000,10.000 L12.000,10.000 L12.000,12.000 L8.000,12.000 L8.000,10.000 ZM8.000,4.000 L16.000,4.000 L16.000,6.000 L8.000,6.000 L8.000,4.000 Z'/%3E%3C/svg%3E");
						}
					}
				}
			}
			
			&--notification{
				> a{
					.side__navigation__item__icon{
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' fill-opacity='0.6' d='M12.000,12.001 L12.000,15.001 L4.000,15.001 L4.000,8.001 C4.000,5.791 5.790,4.001 8.000,4.001 C8.850,4.001 9.640,4.261 10.280,4.721 L11.710,3.291 C11.070,2.781 10.320,2.411 9.500,2.201 L9.500,1.501 C9.500,0.671 8.830,0.001 8.000,0.001 C7.170,0.001 6.500,0.671 6.500,1.501 L6.500,2.201 C3.910,2.861 2.000,5.211 2.000,8.001 L2.000,15.001 L0.000,15.001 L0.000,17.001 L16.000,17.001 L16.000,15.001 L14.000,15.001 L14.000,12.001 L12.000,12.001 ZM8.000,20.001 C9.100,20.001 10.000,19.101 10.000,18.001 L6.000,18.001 C6.000,19.101 6.900,20.001 8.000,20.001 Z'/%3E%3C/svg%3E");
					}
					&:hover{
						.side__navigation__item__icon{
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' d='M12.000,12.001 L12.000,15.001 L4.000,15.001 L4.000,8.001 C4.000,5.791 5.790,4.001 8.000,4.001 C8.850,4.001 9.640,4.261 10.280,4.721 L11.710,3.291 C11.070,2.781 10.320,2.411 9.500,2.201 L9.500,1.501 C9.500,0.671 8.830,0.001 8.000,0.001 C7.170,0.001 6.500,0.671 6.500,1.501 L6.500,2.201 C3.910,2.861 2.000,5.211 2.000,8.001 L2.000,15.001 L0.000,15.001 L0.000,17.001 L16.000,17.001 L16.000,15.001 L14.000,15.001 L14.000,12.001 L12.000,12.001 ZM8.000,20.001 C9.100,20.001 10.000,19.101 10.000,18.001 L6.000,18.001 C6.000,19.101 6.900,20.001 8.000,20.001 Z'/%3E%3C/svg%3E");
						}
					}
				}
				&--unread{
					> a{
						.side__navigation__item__icon{
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' fill-opacity='0.6' d='M12.000,12.001 L12.000,15.001 L4.000,15.001 L4.000,8.001 C4.000,5.791 5.790,4.001 8.000,4.001 C8.850,4.001 9.640,4.261 10.280,4.721 L11.710,3.291 C11.070,2.781 10.320,2.411 9.500,2.201 L9.500,1.501 C9.500,0.671 8.830,0.001 8.000,0.001 C7.170,0.001 6.500,0.671 6.500,1.501 L6.500,2.201 C3.910,2.861 2.000,5.211 2.000,8.001 L2.000,15.001 L0.000,15.001 L0.000,17.001 L16.000,17.001 L16.000,15.001 L14.000,15.001 L14.000,12.001 L12.000,12.001 ZM8.000,20.001 C9.100,20.001 10.000,19.101 10.000,18.001 L6.000,18.001 C6.000,19.101 6.900,20.001 8.000,20.001 Z'/%3E%3Cpath fill-rule='evenodd' fill='rgb(252, 14, 61)' d='M19.998,7.000 C19.998,9.209 18.207,11.000 15.998,11.000 C13.789,11.000 11.999,9.209 11.999,7.000 C11.999,4.791 13.789,3.000 15.998,3.000 C18.207,3.000 19.998,4.791 19.998,7.000 Z'/%3E%3C/svg%3E");
						}
						&:hover{
							.side__navigation__item__icon{
								background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' d='M12.000,12.001 L12.000,15.001 L4.000,15.001 L4.000,8.001 C4.000,5.791 5.790,4.001 8.000,4.001 C8.850,4.001 9.640,4.261 10.280,4.721 L11.710,3.291 C11.070,2.781 10.320,2.411 9.500,2.201 L9.500,1.501 C9.500,0.671 8.830,0.001 8.000,0.001 C7.170,0.001 6.500,0.671 6.500,1.501 L6.500,2.201 C3.910,2.861 2.000,5.211 2.000,8.001 L2.000,15.001 L0.000,15.001 L0.000,17.001 L16.000,17.001 L16.000,15.001 L14.000,15.001 L14.000,12.001 L12.000,12.001 ZM8.000,20.001 C9.100,20.001 10.000,19.101 10.000,18.001 L6.000,18.001 C6.000,19.101 6.900,20.001 8.000,20.001 Z'/%3E%3Cpath fill-rule='evenodd' fill='rgb(252, 14, 61)' d='M19.998,7.000 C19.998,9.209 18.207,11.000 15.998,11.000 C13.789,11.000 11.999,9.209 11.999,7.000 C11.999,4.791 13.789,3.000 15.998,3.000 C18.207,3.000 19.998,4.791 19.998,7.000 Z'/%3E%3C/svg%3E");
							}
						}
					}
	
				}
			}
			
			&--help{
				> a{
					.side__navigation__item__icon{
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' fill-opacity='0.6' d='M9.000,16.000 L11.000,16.000 L11.000,14.000 L9.000,14.000 L9.000,16.000 ZM10.000,-0.000 C4.480,-0.000 0.000,4.480 0.000,10.000 C0.000,15.520 4.480,20.000 10.000,20.000 C15.520,20.000 20.000,15.520 20.000,10.000 C20.000,4.480 15.520,-0.000 10.000,-0.000 ZM10.000,18.000 C5.590,18.000 2.000,14.410 2.000,10.000 C2.000,5.590 5.590,2.000 10.000,2.000 C14.410,2.000 18.000,5.590 18.000,10.000 C18.000,14.410 14.410,18.000 10.000,18.000 ZM10.000,4.000 C7.790,4.000 6.000,5.790 6.000,8.000 L8.000,8.000 C8.000,6.900 8.900,6.000 10.000,6.000 C11.100,6.000 12.000,6.900 12.000,8.000 C12.000,10.000 9.000,9.750 9.000,13.000 L11.000,13.000 C11.000,10.750 14.000,10.500 14.000,8.000 C14.000,5.790 12.210,4.000 10.000,4.000 Z'/%3E%3C/svg%3E");
					}
					&:hover{
						.side__navigation__item__icon{
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' d='M9.000,16.000 L11.000,16.000 L11.000,14.000 L9.000,14.000 L9.000,16.000 ZM10.000,-0.000 C4.480,-0.000 0.000,4.480 0.000,10.000 C0.000,15.520 4.480,20.000 10.000,20.000 C15.520,20.000 20.000,15.520 20.000,10.000 C20.000,4.480 15.520,-0.000 10.000,-0.000 ZM10.000,18.000 C5.590,18.000 2.000,14.410 2.000,10.000 C2.000,5.590 5.590,2.000 10.000,2.000 C14.410,2.000 18.000,5.590 18.000,10.000 C18.000,14.410 14.410,18.000 10.000,18.000 ZM10.000,4.000 C7.790,4.000 6.000,5.790 6.000,8.000 L8.000,8.000 C8.000,6.900 8.900,6.000 10.000,6.000 C11.100,6.000 12.000,6.900 12.000,8.000 C12.000,10.000 9.000,9.750 9.000,13.000 L11.000,13.000 C11.000,10.750 14.000,10.500 14.000,8.000 C14.000,5.790 12.210,4.000 10.000,4.000 Z'/%3E%3C/svg%3E");
						}
					}
				}
			}
			
			&--user{
				> a{
					.side__navigation__item__icon{
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' fill-opacity='0.6' d='M10.000,-0.000 C4.480,-0.000 -0.000,4.480 -0.000,10.000 C-0.000,15.520 4.480,20.000 10.000,20.000 C15.520,20.000 20.000,15.520 20.000,10.000 C20.000,4.480 15.520,-0.000 10.000,-0.000 ZM5.070,16.280 C5.500,15.380 8.120,14.500 10.000,14.500 C11.880,14.500 14.510,15.380 14.930,16.280 C13.570,17.360 11.860,18.000 10.000,18.000 C8.140,18.000 6.430,17.360 5.070,16.280 ZM16.360,14.830 C14.930,13.090 11.460,12.500 10.000,12.500 C8.540,12.500 5.070,13.090 3.640,14.830 C2.620,13.490 2.000,11.820 2.000,10.000 C2.000,5.590 5.590,2.000 10.000,2.000 C14.410,2.000 18.000,5.590 18.000,10.000 C18.000,11.820 17.380,13.490 16.360,14.830 ZM10.000,4.000 C8.060,4.000 6.500,5.560 6.500,7.500 C6.500,9.440 8.060,11.000 10.000,11.000 C11.940,11.000 13.500,9.440 13.500,7.500 C13.500,5.560 11.940,4.000 10.000,4.000 ZM10.000,9.000 C9.170,9.000 8.500,8.330 8.500,7.500 C8.500,6.670 9.170,6.000 10.000,6.000 C10.830,6.000 11.500,6.670 11.500,7.500 C11.500,8.330 10.830,9.000 10.000,9.000 Z'/%3E%3C/svg%3E");
					}
					&:hover{
						.side__navigation__item__icon{
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' d='M10.000,-0.000 C4.480,-0.000 -0.000,4.480 -0.000,10.000 C-0.000,15.520 4.480,20.000 10.000,20.000 C15.520,20.000 20.000,15.520 20.000,10.000 C20.000,4.480 15.520,-0.000 10.000,-0.000 ZM5.070,16.280 C5.500,15.380 8.120,14.500 10.000,14.500 C11.880,14.500 14.510,15.380 14.930,16.280 C13.570,17.360 11.860,18.000 10.000,18.000 C8.140,18.000 6.430,17.360 5.070,16.280 ZM16.360,14.830 C14.930,13.090 11.460,12.500 10.000,12.500 C8.540,12.500 5.070,13.090 3.640,14.830 C2.620,13.490 2.000,11.820 2.000,10.000 C2.000,5.590 5.590,2.000 10.000,2.000 C14.410,2.000 18.000,5.590 18.000,10.000 C18.000,11.820 17.380,13.490 16.360,14.830 ZM10.000,4.000 C8.060,4.000 6.500,5.560 6.500,7.500 C6.500,9.440 8.060,11.000 10.000,11.000 C11.940,11.000 13.500,9.440 13.500,7.500 C13.500,5.560 11.940,4.000 10.000,4.000 ZM10.000,9.000 C9.170,9.000 8.500,8.330 8.500,7.500 C8.500,6.670 9.170,6.000 10.000,6.000 C10.830,6.000 11.500,6.670 11.500,7.500 C11.500,8.330 10.830,9.000 10.000,9.000 Z'/%3E%3C/svg%3E");
						}
					}
				}
			}
			
			> a{
				display: flex;
				align-items:center;
				padding-top: 9px;
				padding-bottom: 9px;
				width: calc(100% - 60px);
				font-weight: 300;
				white-space: nowrap;
				overflow: hidden;
				text-overflow:ellipsis;
				&:hover{
					.side__navigation__item__icon{
						background-color: #ecf3fe;
						border-color:#ecf3fe;
						
					}	
				}
				
				
			}
			&.side__navigation__item--active{
				.side__navigation__item__icon{
					background-color: #0e233c;
					border-color:#0e233c;
				}	
				
				&.side__navigation__item--item1{
					> a{
						.side__navigation__item__icon{
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='16px'%3E%3Cpath fill-rule='evenodd' fill-opacity='1' fill='rgb(255,255,255)' d='M20.000,4.000 L11.000,4.000 L11.000,6.000 L20.000,6.000 L20.000,4.000 ZM20.000,12.000 L11.000,12.000 L11.000,14.000 L20.000,14.000 L20.000,12.000 ZM3.540,8.000 L-0.000,4.460 L1.410,3.050 L3.530,5.170 L7.770,0.930 L9.180,2.340 L3.540,8.000 ZM3.540,16.000 L-0.000,12.460 L1.410,11.050 L3.530,13.170 L7.770,8.930 L9.180,10.340 L3.540,16.000 Z'/%3E%3C/svg%3E");
						}
					}
				}
				&--item2{
					> a{
						.side__navigation__item__icon{
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14px' height='20px'%3E%3Cpath fill-rule='evenodd' fill-opacity='0.6' fill='rgb(14, 35, 60)' d='M-0.000,20.000 L-0.000,18.000 L14.000,18.000 L14.000,20.000 L-0.000,20.000 ZM7.000,17.000 C2.330,13.560 -0.000,10.270 -0.000,7.150 C-0.000,2.460 3.730,-0.000 7.000,-0.000 C10.270,-0.000 14.000,2.460 14.000,7.150 C14.000,10.270 11.670,13.560 7.000,17.000 ZM7.000,2.000 C5.070,2.000 2.000,3.400 2.000,7.150 C2.000,9.300 3.720,11.820 7.000,14.470 C10.280,11.820 12.000,9.310 12.000,7.150 C12.000,3.400 8.930,2.000 7.000,2.000 ZM7.000,9.000 C5.900,9.000 5.000,8.100 5.000,7.000 C5.000,5.900 5.900,5.000 7.000,5.000 C8.105,5.000 9.000,5.895 9.000,7.000 C9.000,8.105 8.105,9.000 7.000,9.000 Z'/%3E%3C/svg%3E");
						}
					}
				}
				&--item3{
					> a{
						.side__navigation__item__icon{
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='22px' height='16px'%3E%3Cpath fill-rule='evenodd' fill-opacity='0.6' fill='rgb(14, 35, 60)' d='M18.000,16.000 L18.000,13.000 C18.000,11.320 17.040,10.060 15.670,9.130 C18.430,9.530 22.000,10.820 22.000,13.000 L22.000,16.000 L18.000,16.000 ZM14.000,8.000 C13.530,8.000 13.090,7.900 12.670,7.760 C13.500,6.730 14.000,5.420 14.000,4.000 C14.000,2.580 13.500,1.270 12.670,0.240 C13.090,0.100 13.530,0.000 14.000,0.000 C16.210,0.000 18.000,1.790 18.000,4.000 C18.000,6.210 16.210,8.000 14.000,8.000 ZM8.000,8.000 C5.790,8.000 4.000,6.210 4.000,4.000 C4.000,1.790 5.790,0.000 8.000,0.000 C10.210,0.000 12.000,1.790 12.000,4.000 C12.000,6.210 10.210,8.000 8.000,8.000 ZM8.000,2.000 C6.900,2.000 6.000,2.900 6.000,4.000 C6.000,5.100 6.900,6.000 8.000,6.000 C9.100,6.000 10.000,5.100 10.000,4.000 C10.000,2.900 9.100,2.000 8.000,2.000 ZM16.000,13.000 L16.000,16.000 L-0.000,16.000 L-0.000,13.000 C-0.000,10.340 5.330,9.000 8.000,9.000 C10.670,9.000 16.000,10.340 16.000,13.000 ZM2.000,13.010 L2.000,14.000 L14.000,14.000 L14.000,13.000 C13.800,12.290 10.700,11.000 8.000,11.000 C5.300,11.000 2.200,12.290 2.000,13.010 Z'/%3E%3C/svg%3E");
						}
					}
				}
				&--item4{
					> a{
						.side__navigation__item__icon{
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill-opacity='0.6' fill='rgb(14, 35, 60)' d='M2.000,4.000 L-0.000,4.000 L-0.000,18.000 C-0.000,19.100 0.900,20.000 2.000,20.000 L16.000,20.000 L16.000,18.000 L2.000,18.000 L2.000,4.000 ZM18.000,0.000 L6.000,0.000 C4.900,0.000 4.000,0.900 4.000,2.000 L4.000,14.000 C4.000,15.100 4.900,16.000 6.000,16.000 L18.000,16.000 C19.100,16.000 20.000,15.100 20.000,14.000 L20.000,2.000 C20.000,0.900 19.100,0.000 18.000,0.000 ZM18.000,14.000 L6.000,14.000 L6.000,2.000 L18.000,2.000 L18.000,14.000 ZM8.000,7.000 L16.000,7.000 L16.000,9.000 L8.000,9.000 L8.000,7.000 ZM8.000,10.000 L12.000,10.000 L12.000,12.000 L8.000,12.000 L8.000,10.000 ZM8.000,4.000 L16.000,4.000 L16.000,6.000 L8.000,6.000 L8.000,4.000 Z'/%3E%3C/svg%3E");
						}
					}
				}
				
				&--notification{
					> a{
						.side__navigation__item__icon{
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' fill-opacity='0.6' d='M12.000,12.001 L12.000,15.001 L4.000,15.001 L4.000,8.001 C4.000,5.791 5.790,4.001 8.000,4.001 C8.850,4.001 9.640,4.261 10.280,4.721 L11.710,3.291 C11.070,2.781 10.320,2.411 9.500,2.201 L9.500,1.501 C9.500,0.671 8.830,0.001 8.000,0.001 C7.170,0.001 6.500,0.671 6.500,1.501 L6.500,2.201 C3.910,2.861 2.000,5.211 2.000,8.001 L2.000,15.001 L0.000,15.001 L0.000,17.001 L16.000,17.001 L16.000,15.001 L14.000,15.001 L14.000,12.001 L12.000,12.001 ZM8.000,20.001 C9.100,20.001 10.000,19.101 10.000,18.001 L6.000,18.001 C6.000,19.101 6.900,20.001 8.000,20.001 Z'/%3E%3C/svg%3E");
						}
						&:hover{
							.side__navigation__item__icon{
								background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' d='M12.000,12.001 L12.000,15.001 L4.000,15.001 L4.000,8.001 C4.000,5.791 5.790,4.001 8.000,4.001 C8.850,4.001 9.640,4.261 10.280,4.721 L11.710,3.291 C11.070,2.781 10.320,2.411 9.500,2.201 L9.500,1.501 C9.500,0.671 8.830,0.001 8.000,0.001 C7.170,0.001 6.500,0.671 6.500,1.501 L6.500,2.201 C3.910,2.861 2.000,5.211 2.000,8.001 L2.000,15.001 L0.000,15.001 L0.000,17.001 L16.000,17.001 L16.000,15.001 L14.000,15.001 L14.000,12.001 L12.000,12.001 ZM8.000,20.001 C9.100,20.001 10.000,19.101 10.000,18.001 L6.000,18.001 C6.000,19.101 6.900,20.001 8.000,20.001 Z'/%3E%3C/svg%3E");
							}
						}
					}
					&--unread{
						> a{
							.side__navigation__item__icon{
								background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' fill-opacity='0.6' d='M12.000,12.001 L12.000,15.001 L4.000,15.001 L4.000,8.001 C4.000,5.791 5.790,4.001 8.000,4.001 C8.850,4.001 9.640,4.261 10.280,4.721 L11.710,3.291 C11.070,2.781 10.320,2.411 9.500,2.201 L9.500,1.501 C9.500,0.671 8.830,0.001 8.000,0.001 C7.170,0.001 6.500,0.671 6.500,1.501 L6.500,2.201 C3.910,2.861 2.000,5.211 2.000,8.001 L2.000,15.001 L0.000,15.001 L0.000,17.001 L16.000,17.001 L16.000,15.001 L14.000,15.001 L14.000,12.001 L12.000,12.001 ZM8.000,20.001 C9.100,20.001 10.000,19.101 10.000,18.001 L6.000,18.001 C6.000,19.101 6.900,20.001 8.000,20.001 Z'/%3E%3Cpath fill-rule='evenodd' fill='rgb(252, 14, 61)' d='M19.998,7.000 C19.998,9.209 18.207,11.000 15.998,11.000 C13.789,11.000 11.999,9.209 11.999,7.000 C11.999,4.791 13.789,3.000 15.998,3.000 C18.207,3.000 19.998,4.791 19.998,7.000 Z'/%3E%3C/svg%3E");
							}
							&:hover{
								.side__navigation__item__icon{
									background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' d='M12.000,12.001 L12.000,15.001 L4.000,15.001 L4.000,8.001 C4.000,5.791 5.790,4.001 8.000,4.001 C8.850,4.001 9.640,4.261 10.280,4.721 L11.710,3.291 C11.070,2.781 10.320,2.411 9.500,2.201 L9.500,1.501 C9.500,0.671 8.830,0.001 8.000,0.001 C7.170,0.001 6.500,0.671 6.500,1.501 L6.500,2.201 C3.910,2.861 2.000,5.211 2.000,8.001 L2.000,15.001 L0.000,15.001 L0.000,17.001 L16.000,17.001 L16.000,15.001 L14.000,15.001 L14.000,12.001 L12.000,12.001 ZM8.000,20.001 C9.100,20.001 10.000,19.101 10.000,18.001 L6.000,18.001 C6.000,19.101 6.900,20.001 8.000,20.001 Z'/%3E%3Cpath fill-rule='evenodd' fill='rgb(252, 14, 61)' d='M19.998,7.000 C19.998,9.209 18.207,11.000 15.998,11.000 C13.789,11.000 11.999,9.209 11.999,7.000 C11.999,4.791 13.789,3.000 15.998,3.000 C18.207,3.000 19.998,4.791 19.998,7.000 Z'/%3E%3C/svg%3E");
								}
							}
						}
		
					}
				}
				
				&--help{
					> a{
						.side__navigation__item__icon{
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' fill-opacity='0.6' d='M9.000,16.000 L11.000,16.000 L11.000,14.000 L9.000,14.000 L9.000,16.000 ZM10.000,-0.000 C4.480,-0.000 0.000,4.480 0.000,10.000 C0.000,15.520 4.480,20.000 10.000,20.000 C15.520,20.000 20.000,15.520 20.000,10.000 C20.000,4.480 15.520,-0.000 10.000,-0.000 ZM10.000,18.000 C5.590,18.000 2.000,14.410 2.000,10.000 C2.000,5.590 5.590,2.000 10.000,2.000 C14.410,2.000 18.000,5.590 18.000,10.000 C18.000,14.410 14.410,18.000 10.000,18.000 ZM10.000,4.000 C7.790,4.000 6.000,5.790 6.000,8.000 L8.000,8.000 C8.000,6.900 8.900,6.000 10.000,6.000 C11.100,6.000 12.000,6.900 12.000,8.000 C12.000,10.000 9.000,9.750 9.000,13.000 L11.000,13.000 C11.000,10.750 14.000,10.500 14.000,8.000 C14.000,5.790 12.210,4.000 10.000,4.000 Z'/%3E%3C/svg%3E");
						}
						&:hover{
							.side__navigation__item__icon{
								background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' d='M9.000,16.000 L11.000,16.000 L11.000,14.000 L9.000,14.000 L9.000,16.000 ZM10.000,-0.000 C4.480,-0.000 0.000,4.480 0.000,10.000 C0.000,15.520 4.480,20.000 10.000,20.000 C15.520,20.000 20.000,15.520 20.000,10.000 C20.000,4.480 15.520,-0.000 10.000,-0.000 ZM10.000,18.000 C5.590,18.000 2.000,14.410 2.000,10.000 C2.000,5.590 5.590,2.000 10.000,2.000 C14.410,2.000 18.000,5.590 18.000,10.000 C18.000,14.410 14.410,18.000 10.000,18.000 ZM10.000,4.000 C7.790,4.000 6.000,5.790 6.000,8.000 L8.000,8.000 C8.000,6.900 8.900,6.000 10.000,6.000 C11.100,6.000 12.000,6.900 12.000,8.000 C12.000,10.000 9.000,9.750 9.000,13.000 L11.000,13.000 C11.000,10.750 14.000,10.500 14.000,8.000 C14.000,5.790 12.210,4.000 10.000,4.000 Z'/%3E%3C/svg%3E");
							}
						}
					}
				}
				
				&--user{
					> a{
						.side__navigation__item__icon{
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' fill-opacity='0.6' d='M10.000,-0.000 C4.480,-0.000 -0.000,4.480 -0.000,10.000 C-0.000,15.520 4.480,20.000 10.000,20.000 C15.520,20.000 20.000,15.520 20.000,10.000 C20.000,4.480 15.520,-0.000 10.000,-0.000 ZM5.070,16.280 C5.500,15.380 8.120,14.500 10.000,14.500 C11.880,14.500 14.510,15.380 14.930,16.280 C13.570,17.360 11.860,18.000 10.000,18.000 C8.140,18.000 6.430,17.360 5.070,16.280 ZM16.360,14.830 C14.930,13.090 11.460,12.500 10.000,12.500 C8.540,12.500 5.070,13.090 3.640,14.830 C2.620,13.490 2.000,11.820 2.000,10.000 C2.000,5.590 5.590,2.000 10.000,2.000 C14.410,2.000 18.000,5.590 18.000,10.000 C18.000,11.820 17.380,13.490 16.360,14.830 ZM10.000,4.000 C8.060,4.000 6.500,5.560 6.500,7.500 C6.500,9.440 8.060,11.000 10.000,11.000 C11.940,11.000 13.500,9.440 13.500,7.500 C13.500,5.560 11.940,4.000 10.000,4.000 ZM10.000,9.000 C9.170,9.000 8.500,8.330 8.500,7.500 C8.500,6.670 9.170,6.000 10.000,6.000 C10.830,6.000 11.500,6.670 11.500,7.500 C11.500,8.330 10.830,9.000 10.000,9.000 Z'/%3E%3C/svg%3E");
						}
						&:hover{
							.side__navigation__item__icon{
								background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' d='M10.000,-0.000 C4.480,-0.000 -0.000,4.480 -0.000,10.000 C-0.000,15.520 4.480,20.000 10.000,20.000 C15.520,20.000 20.000,15.520 20.000,10.000 C20.000,4.480 15.520,-0.000 10.000,-0.000 ZM5.070,16.280 C5.500,15.380 8.120,14.500 10.000,14.500 C11.880,14.500 14.510,15.380 14.930,16.280 C13.570,17.360 11.860,18.000 10.000,18.000 C8.140,18.000 6.430,17.360 5.070,16.280 ZM16.360,14.830 C14.930,13.090 11.460,12.500 10.000,12.500 C8.540,12.500 5.070,13.090 3.640,14.830 C2.620,13.490 2.000,11.820 2.000,10.000 C2.000,5.590 5.590,2.000 10.000,2.000 C14.410,2.000 18.000,5.590 18.000,10.000 C18.000,11.820 17.380,13.490 16.360,14.830 ZM10.000,4.000 C8.060,4.000 6.500,5.560 6.500,7.500 C6.500,9.440 8.060,11.000 10.000,11.000 C11.940,11.000 13.500,9.440 13.500,7.500 C13.500,5.560 11.940,4.000 10.000,4.000 ZM10.000,9.000 C9.170,9.000 8.500,8.330 8.500,7.500 C8.500,6.670 9.170,6.000 10.000,6.000 C10.830,6.000 11.500,6.670 11.500,7.500 C11.500,8.330 10.830,9.000 10.000,9.000 Z'/%3E%3C/svg%3E");
							}
						}
					}
				}
			}
			&__toggle{
				position: absolute;
				right: 18px;
				top: 9px;
				height: 36px;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='8px'%3E%3Cpath fill-rule='evenodd' fill-opacity='0.8' fill='rgb(14, 35, 60)' d='M10.590,0.437 L6.000,4.818 L1.410,0.437 L0.000,1.786 L6.000,7.524 L12.000,1.786 L10.590,0.437 Z'/%3E%3C/svg%3E");
				background-repeat: no-repeat;
				width: 12px;
				background-position: right center;
				
				&:after{
					content:'';
					position: absolute;
					height: 36px;
					width: 60px;
					right: -18px;
					top: 9px;
					cursor: pointer;
				}
				
			}
			&__icon{
				width: 36px;
				height: 36px;
				border-radius: 50%;
				background-color: #fff;
				border:1px solid #ecf3fe;
				
				background-position: center center;
				background-repeat: no-repeat;
				transition:.15s;
				flex-shrink:0;
			}
			&__label{
				margin-left: 9px;
				white-space: nowrap;
				overflow: hidden;
			}
			
			&__submenu{
				padding-left: 45px;
				display: none;
				transition:0s;
				&__item{
					a:not(.user-dropdown__bottom__logout__link){
						display: flex;
						margin-bottom: 9px;
						height: 36px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						align-items:center;
						color: #4688f1;
						font-weight: 300;
						border:1px solid transparent;
						border-radius:4px;
						transition:.15s;
						padding-left: 12px;
						padding-right: 12px;
						&:hover{
							color: #1349e4;
							border-color:rgba(#4688f1,0.1);
						}
					}
					&--selected{
						a{
							background-color: #ecf3fe;
							border-color:#ecf3fe;
							color: #0e233c;
						}
					}
				}
			}
		}
	}
	
	&--collapsed{
		width: 72px;
		
		.side__navigation__item__toggle,
		.side__navigation__item__label{
			display: none;
		}	
		.side__navigation__item > a{
			width: 100%;
		}
		.dashboard-link__label{
			display: none;
		}
		
		.side__navigation__item__submenu{
			display: block;
			opacity: 0;
			position: absolute;
			left: calc(100% - 9px);
			background-color: #fff;
			top: 45px;
			pointer-events:none;
			padding-left: 0;
			padding-right: 0;
			transition:.15s;
			display: none !important;
		}
		
		
		
		.side__navigation__item--level1{
			> a{
				&:after{
					position: absolute;
					content:attr(data-title);
					left: calc(100% - 9px);
					top: 9px;
					height: 36px;
					display: flex;
					align-items:center;
					justify-content: center;
					padding-left: 18px;
					padding-right: 18px;
					opacity: 0;
					pointer-events:none;
					background-color: #ecf3fe;
					border-radius: 4px;
					transition:.15s;
					z-index: 10;
				}
				&:before{
					content:'';
					position: absolute;
					top: 9px;
				    left: 0;
				    right: 0;
				    bottom: 9px;
				}
			}
			&:hover{
				> a{
					&:after{
						opacity: 1;
						pointer-events:auto;
					}
				}
				.side__navigation__item__submenu{
					//opacity: 1;
					//pointer-events:auto;
				}
			}
		}
	}
}

.dashboard-link{
	display: flex;
	align-items:center;
	&__icon{
		width: 36px;
		height: 36px;
		border-radius: 50%;
		background-color: #fff;
		border:1px solid #ecf3fe;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16px' height='18px'%3E%3Cpath fill-rule='evenodd' opacity='0.6' fill='rgb(14, 35, 60)' d='M8.000,2.500 L14.000,7.000 L14.000,16.000 L2.000,16.000 L2.000,7.000 L8.000,2.500 M8.000,-0.000 L-0.000,6.000 L-0.000,18.000 L16.000,18.000 L16.000,6.000 L8.000,-0.000 Z'/%3E%3C/svg%3E");
		background-position: center center;
		background-repeat: no-repeat;
		
		transition:.15s;
	}
	&__label{
		margin-left: 9px;
		white-space: nowrap;
		overflow: hidden;
	}
	&:hover{
		.dashboard-link__icon{
			background-color: #0e233c;
			border-color:#0e233c;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16px' height='18px'%3E%3Cpath fill-rule='evenodd' opacity='1' fill='%23ffffff' d='M8.000,2.500 L14.000,7.000 L14.000,16.000 L2.000,16.000 L2.000,7.000 L8.000,2.500 M8.000,-0.000 L-0.000,6.000 L-0.000,18.000 L16.000,18.000 L16.000,6.000 L8.000,-0.000 Z'/%3E%3C/svg%3E");
		}	
	}
}



@media screen and (max-width:1180px){
	.side{
		position: fixed;
		top: 0;
		bottom: 0;
		z-index: 100;
		left: 0;
		&__top{
			position: relative;
		}
		&__center{
			position: relative;
		    top:0;
		    z-index: 10;
		    flex-shrink: 1;
		    flex-grow: 1;
		}
		&__bottom{
			position: relative;
		}
		
		&:not(.side--collapsed){
			.side__center{
			    flex-shrink: 1;
			    flex-grow: 1;
			    overflow-y: auto;
			    -webkit-overflow-scrolling: touch;
			}
		}
	}
}

@media screen and (min-width:1024px){
	.side__navigation__item--hide-md{
		display: none;
	}
}
@media screen and (max-width:600px){
	body:not(.side-collapsed){
		.inner-wrapper{
			position: relative;
			&:after{
				//content:'';
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: rgba(#000,0.35);
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 99;
				transition:.15s;
			}
		}
	}
	
	.side{
		width: 100%;
	}
	
}

@media screen and (orientation:landscape) and (max-height:600px) and (max-width:1023px){
	.side__center{
		overflow-y: auto;
		overflow-x: hidden;
		-webkit-overflow-scrolling: touch;
	}
	.side--collapsed .side__navigation__item--level1:hover > a:after{
		display: none;
	}
}