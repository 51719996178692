//
// Grid Options
//
// To avoid your options getting overridden by an update,
// copy and paste the below options to your own sass file
// and include it before this file.

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 481px,
        ph: 600px,
        md: 768px,
        lg: 992px,
        xl: 1366px,
) !default;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 100%,
        ph: 100%,
        md: 100%,
        lg: 100%,
        xl: 1242px,
) !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 18px !default;

// END of Grid Options

// Bootstrap file imports
@import "breakpoints/mixins/breakpoints";
@import "grid/bootstrap-grid";
@import "sizing/utilities/sizing";
@import "layout/utilities/spacing";
@import "layout/mixins/visibility";
@import "layout/utilities/visibility";

.container-fluid,
.container{
	// padding-left: 18px;
	// padding-right: 18px;
    &.centred {
        padding-top: 12px;
        h3 {
            padding-top: 18px;
        }
    }
}

.container-left-fluid,
.container-right-fluid{
	// padding-right: 18px;
	// padding-left: 18px;
}

.f-grow-1{
	flex-grow: 1;
}

[data-column-width="60%"]{
	width: 60%;
}
[data-column-width="20%"]{
	width: 20%;
}

// @media screen and (min-width:1680px){
// }

// @media screen and (min-width:1448px){
// }

// @media screen and (max-width:991px){
// }

// @media screen and (max-width:767px){
// }

// @media screen and (max-width:575px) {
// }

// @media screen and (max-width:374px) {
// }

@media screen and (min-width:481px){
    .container {
        padding-left: 12px;
        padding-right: 12px;
    }
}

@media screen and (min-width:767px){
    .container {
        padding-left: 18px;
        padding-right: 18px;
    }
}

@media screen and (min-width:992px){
    .container {
        padding-left: 36px;
        padding-right: 36px;
    }
}