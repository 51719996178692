.topbar{
	background-color: #fff;
	border-bottom: 1px solid #e8e8e8;
	height: var(--navbar-height);
	position: sticky;
	top: 0;
	z-index: 99;
	
	.container-fluid{
		.overlay & {
			padding-left: 0;
			padding-right: 0;
		}	
	}

	&__inner{
		display: flex;
		margin-left: -9px;
		margin-right: -9px;
		align-items:center;
		justify-content: space-between;
		height: var(--navbar-height);
	}
	
	&__logo{
		padding-left: 9px;
		padding-right: 9px;
		width: auto;
		flex-shrink:0;
		flex-grow:0;
		img{
			width: 103px;
			height: 24px;
			display: block;
		}
	}
	
	&__previous{
		padding-left: 18px;
		background-position: left center;
		padding-right: 12px;
		width: auto;
		flex-shrink:0;
		flex-grow:0;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='12px'%3E%3Cpath fill-rule='evenodd' fill='rgb(166, 166, 166)' d='M6.000,-0.000 L7.057,1.057 L2.872,5.250 L12.000,5.250 L12.000,6.750 L2.872,6.750 L7.057,10.943 L6.000,12.000 L-0.001,6.000 L6.000,-0.000 Z'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
	}

	&__home{
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Laag_1' x='0px' y='0px' viewBox='0 0 18 18' opacity='0.6' style='enable-background:new 0 0 18 18;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%2314233A;%7D%0A%3C/style%3E%3Cpath class='st0' d='M0.99,18V5.99l8.06-6.01l7.96,6.01V18h-5.86v-7.13H6.82V18H0.99z M2.49,16.5h2.83V9.37h7.33v7.13h2.85V6.74 L9.05,1.86L2.49,6.74V16.5z'/%3E%3C/svg%3E");
		background-size: 18px auto;
		background-repeat: no-repeat;
		width: 36px;
		height: 36px;
		margin-right: 18px;
        margin-left: 9px;
		flex-shrink: 0;
		background-position: center center;
		border: 2px solid rgba(14, 35, 60, 0.6);
		border-radius: 50%;
		&:hover{
			border: 2px solid rgba(14, 35, 60, 1);
			background-color: rgba(14, 35, 60, 1);
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Laag_1' x='0px' y='0px' viewBox='0 0 18 18' opacity='1' style='enable-background:new 0 0 18 18;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%2314233A;%7D%0A%3C/style%3E%3Cpath class='st0' d='M0.99,18V5.99l8.06-6.01l7.96,6.01V18h-5.86v-7.13H6.82V18H0.99z M2.49,16.5h2.83V9.37h7.33v7.13h2.85V6.74 L9.05,1.86L2.49,6.74V16.5z'/%3E%3C/svg%3E");
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Laag_1' x='0px' y='0px' viewBox='0 0 18 18' opacity='1' style='enable-background:new 0 0 18 18;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23ffffff;%7D%0A%3C/style%3E%3Cpath class='st0' d='M0.99,18V5.99l8.06-6.01l7.96,6.01V18h-5.86v-7.13H6.82V18H0.99z M2.49,16.5h2.83V9.37h7.33v7.13h2.85V6.74 L9.05,1.86L2.49,6.74V16.5z'/%3E%3C/svg%3E");
		}
	}
	
	&__title{
		
		color: var(--primary-blue);

		:first-of-type {
            font-weight: bold;
        }
        
		.overlay & {
			margin-left: 9px;
		}
		
		&--icon{
			padding-left: 27px;
			background-position: left center;
			background-repeat: no-repeat;
			-webkit-background-size: 18px auto;
			background-size: 18px auto;
        }

        &--rentals{
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18px' height='18px'%3E%3Cpath fill-rule='evenodd' fill='rgb(253, 153, 39)' d='M17.469,11.169 L11.169,17.469 C10.845,17.802 10.395,18.000 9.900,18.000 C9.405,18.000 8.955,17.802 8.631,17.478 L0.531,9.378 C0.198,9.045 0.000,8.595 0.000,8.100 L0.000,1.800 C0.000,0.810 0.810,0.000 1.800,0.000 L8.100,0.000 C8.595,0.000 9.045,0.198 9.369,0.522 L17.469,8.622 C17.793,8.946 18.000,9.405 18.000,9.900 C18.000,10.395 17.802,10.845 17.469,11.169 ZM8.100,1.791 L8.100,1.800 L1.800,1.800 L1.800,8.100 L9.900,16.209 L16.200,9.891 L8.100,1.791 ZM4.050,5.400 C3.304,5.400 2.700,4.796 2.700,4.050 C2.700,3.304 3.304,2.700 4.050,2.700 C4.796,2.700 5.400,3.304 5.400,4.050 C5.400,4.796 4.796,5.400 4.050,5.400 Z'/%3E%3C/svg%3E");
        }
        &--invoices, &--management {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18px' height='18px'%3E%3Cpath fill-rule='evenodd' fill='rgb(253, 153, 39)' d='M14.400,18.000 L3.600,18.000 C2.106,18.000 0.900,16.794 0.900,15.300 L0.900,12.600 L3.600,12.600 L3.600,0.000 L4.950,1.350 L6.300,0.000 L7.650,1.350 L9.000,0.000 L10.350,1.350 L11.700,0.000 L13.050,1.350 L14.400,0.000 L15.750,1.350 L17.100,0.000 L17.100,15.300 C17.100,16.794 15.894,18.000 14.400,18.000 ZM2.700,14.400 L2.700,15.300 C2.700,15.795 3.105,16.200 3.600,16.200 L11.700,16.200 L11.700,14.400 L2.700,14.400 ZM15.300,2.700 L5.400,2.700 L5.400,12.600 L13.500,12.600 L13.500,15.300 C13.500,15.795 13.905,16.200 14.400,16.200 C14.895,16.200 15.300,15.795 15.300,15.300 L15.300,2.700 ZM12.600,7.200 L14.400,7.200 L14.400,9.000 L12.600,9.000 L12.600,7.200 ZM12.600,4.500 L14.400,4.500 L14.400,6.300 L12.600,6.300 L12.600,4.500 ZM6.300,7.200 L11.700,7.200 L11.700,9.000 L6.300,9.000 L6.300,7.200 ZM6.300,4.500 L11.700,4.500 L11.700,6.300 L6.300,6.300 L6.300,4.500 Z'/%3E%3C/svg%3E");
        }
        &--repairs{
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18px' height='16px'%3E%3Cpath fill-rule='evenodd' fill='rgb(253, 153, 39)' d='M16.200,4.400 L13.500,4.400 L13.500,2.600 C13.500,1.610 12.690,0.800 11.700,0.800 L6.300,0.800 C5.310,0.800 4.500,1.610 4.500,2.600 L4.500,4.400 L1.800,4.400 C0.810,4.400 0.000,5.210 0.000,6.200 L0.000,15.200 L18.000,15.200 L18.000,6.200 C18.000,5.210 17.190,4.400 16.200,4.400 ZM6.300,2.600 L11.700,2.600 L11.700,4.400 L6.300,4.400 L6.300,2.600 ZM16.200,13.400 L1.800,13.400 L1.800,10.700 L3.600,10.700 L3.600,11.600 L5.400,11.600 L5.400,10.700 L12.600,10.700 L12.600,11.600 L14.400,11.600 L14.400,10.700 L16.200,10.700 L16.200,13.400 ZM14.400,8.900 L14.400,8.000 L12.600,8.000 L12.600,8.900 L5.400,8.900 L5.400,8.000 L3.600,8.000 L3.600,8.900 L1.800,8.900 L1.800,6.200 L4.500,6.200 L13.500,6.200 L16.200,6.200 L16.200,8.900 L14.400,8.900 Z'/%3E%3C/svg%3E");
        }
        &--filters{
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18px' height='18px'%3E%3Cpath fill-rule='evenodd' fill='rgb(253, 153, 39)' d='M-0.000,14.000 L-0.000,16.000 L6.000,16.000 L6.000,14.000 L-0.000,14.000 ZM-0.000,2.000 L-0.000,4.000 L10.000,4.000 L10.000,2.000 L-0.000,2.000 ZM10.000,18.000 L10.000,16.000 L18.000,16.000 L18.000,14.000 L10.000,14.000 L10.000,12.000 L8.000,12.000 L8.000,18.000 L10.000,18.000 ZM4.000,6.000 L4.000,8.000 L-0.000,8.000 L-0.000,10.000 L4.000,10.000 L4.000,12.000 L6.000,12.000 L6.000,6.000 L4.000,6.000 ZM18.000,10.000 L18.000,8.000 L8.000,8.000 L8.000,10.000 L18.000,10.000 ZM12.000,6.000 L14.000,6.000 L14.000,4.000 L18.000,4.000 L18.000,2.000 L14.000,2.000 L14.000,-0.000 L12.000,-0.000 L12.000,6.000 Z'/%3E%3C/svg%3E");
        }
        &--segment{
            padding-left: 0px;
        }
        &--scan {
            background-image: url("data:image/svg+xml,%0A%3Csvg version='1.2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 46' width='24' height='24' fill='%23f19e39'%3E%3Cpath id='scannen-icon' fill-rule='evenodd' class='s0' d='m0.7 9.8v-9.1h9.1v3.3h-5.8v5.8zm37.3 0v-5.8h-5.8v-3.3h9.1v9.1zm-37.3 35.5v-9.1h3.3v5.8h5.7v3.3zm31.5 0v-3.3h5.8v-5.8h3.3v9.1zm-22-9.6h21.5v-25.4h-21.5zm0 3.4q-1.3 0-2.4-1-1-1.1-1-2.4v-25.5q0-1.3 1-2.4 1.1-1 2.4-1h21.5q1.3 0 2.4 1 1 1.1 1 2.4v25.5q0 1.3-1 2.4-1.1 1-2.4 1zm5.1-20.6h11.2v-3.1h-11.2zm0 6h11.2v-3.1h-11.2zm0 6h11.2v-3.1h-11.2zm-5.1 5.2v-25.5z'/%3E%3C/svg%3E");
        }
        &--products, &--articles, &--requests {
            background-image: url("data:image/svg+xml,%3Csvg verson='1.1' id='Laag_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 18 18' style='enable-background:new 0 0 18 18;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23F4992E;%7D%0A%3C/style%3E%3Cpath class='st0' d='M1.35,16.65h2.7v-7.2h9.9v7.2h2.7V6.3L9,3.24L1.35,6.3V16.65z M0,18V5.4l9-3.6l9,3.6V18h-5.4v-7.2H5.4V18H0z M6.53,18v-1.35h1.35V18H6.53z M8.32,15.3v-1.35h1.35v1.35H8.32z M10.12,18v-1.35h1.35V18H10.12z M4.05,9.45h9.9H4.05z'/%3E%3C/svg%3E");
            background-position: left top -1px;
        }
        &--transport{
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' fill-rule='evenodd' fill='rgb(253, 153, 39)' clip-rule='evenodd'%3E%3Cpath d='M5 11v1h8v-7h-10v-1c0-.552.448-1 1-1h10c.552 0 1 .448 1 1v2h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035v4.48c0 1.089-.743 2-2 2h-1c0 1.656-1.344 3-3 3s-3-1.344-3-3h-4c0 1.656-1.344 3-3 3s-3-1.344-3-3h-1c-.552 0-1-.448-1-1v-6h-2v-2h7v2h-3zm3 5.8c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm10 0c.662 0 1.2.538 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.662 0-1.2-.538-1.2-1.2 0-.662.538-1.2 1.2-1.2zm-3-2.8h-10v2h.765c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h5.53c.549-.614 1.347-1 2.235-1 .888 0 1.686.386 2.235 1h1.765v-4.575l-1.711-2.929c-.179-.307-.508-.496-.863-.496h-4.426v6zm1-5v3h5l-1.427-2.496c-.178-.312-.509-.504-.868-.504h-2.705zm-16-3h8v2h-8v-2z'/%3E%3C/svg%3E");
        }
        &--addossiers{
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18px' height='18px'%3E%3Cpath fill-rule='evenodd' fill='rgb(253, 153, 39)' d='M17.469,11.169 L11.169,17.469 C10.845,17.802 10.395,18.000 9.900,18.000 C9.405,18.000 8.955,17.802 8.631,17.478 L0.531,9.378 C0.198,9.045 0.000,8.595 0.000,8.100 L0.000,1.800 C0.000,0.810 0.810,0.000 1.800,0.000 L8.100,0.000 C8.595,0.000 9.045,0.198 9.369,0.522 L17.469,8.622 C17.793,8.946 18.000,9.405 18.000,9.900 C18.000,10.395 17.802,10.845 17.469,11.169 ZM8.100,1.791 L8.100,1.800 L1.800,1.800 L1.800,8.100 L9.900,16.209 L16.200,9.891 L8.100,1.791 ZM4.050,5.400 C3.304,5.400 2.700,4.796 2.700,4.050 C2.700,3.304 3.304,2.700 4.050,2.700 C4.796,2.700 5.400,3.304 5.400,4.050 C5.400,4.796 4.796,5.400 4.050,5.400 Z'/%3E%3C/svg%3E");
        }
        &--inspections{
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 -960 960 960' width='24' height='24' fill='%23f19e39'%3E%3Cpath d='M160-120q-33 0-56.5-23.5T80-200v-560q0-33 23.5-56.5T160-840h640q33 0 56.5 23.5T880-760v560q0 33-23.5 56.5T800-120H160Zm0-80h640v-560H160v560Zm40-80h200v-80H200v80Zm382-80 198-198-57-57-141 142-57-57-56 57 113 113Zm-382-80h200v-80H200v80Zm0-160h200v-80H200v80Zm-40 400v-560 560Z'/%3E%3C/svg%3E");
        }
	}
	
	&__right{
		padding-left: 9px;
		padding-right: 9px;
		flex-shrink:1;
		flex-grow:1;
		display: flex;
		align-items:center;
		justify-content: flex-end;
	}
	&__icons{
		display: flex;
		justify-content: space-between;
	}
	&__user{
		flex-grow:1;
		flex-shrink:1;
		max-width: 288px;
		margin-right: 36px;
	}
	
	&__trigger{
		display: none;
	}
	
	.overlay & {
		border-bottom: 0;
	}

    .bread-crumb-trail {
        a {
            display:inline-block;
        }
    }
}

.trigger-menu{
	display: flex;
	justify-content: flex-end;
	align-items:center;
	cursor: pointer;
	
	&__label{
		color: var(--primary-blue);
		font-weight: normal;
		font-size: 14px;
		margin-right: 9px;
		@include text-crop($line-height:1.5, $top-adjustment: 1px ,$bottom-adjustment:-4px);
	}
	&__icon{
		width: 18px;
		height: 14px;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='14' viewBox='0 0 18 14'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %230c2f72; fill-rule: evenodd; %7D %3C/style%3E%3C/defs%3E%3Cpath id='menu-icon' class='cls-1' d='M282,25h18v2H282V25Zm0,6h18v2H282V31Zm0,6h18v2H282V37Z' transform='translate(-282 -25)'/%3E%3C/svg%3E");
	}
}

.user-dropdown{
	display: flex;
	flex-direction: column;
	position: relative;
	min-width: 250px;
	background-color: #fff;
	z-index: 3;
	&__top{
		padding-left: 18px;
		padding-right: 18px;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='8px'%3E%3Cpath fill-rule='evenodd' fill-opacity='1' fill='%230c2f72' opacity='1' d='M10.590,0.437 L6.000,4.818 L1.410,0.437 L0.000,1.786 L6.000,7.524 L12.000,1.786 L10.590,0.437 Z'/%3E%3C/svg%3E");
		background-position: right 18px center;
		display: flex;
		align-items:center;
		cursor: pointer;
		height: 56px;
		background-repeat: no-repeat;
		font-weight: 300;
		border:1px solid transparent;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		background-color: #fff;
		z-index: 3;
		position: relative;
		.icon--user{
			flex-shrink:0;
			flex-grow:0;
			margin-right: 9px;
			opacity: 0.6;
		}
		
	}
	&__name{
		//opacity: 0.8;
		padding-right: 18px;
		text-overflow:ellipsis;
		overflow: hidden;
		flex-shrink: 1;
		flex-grow:1;
		font-weight: bold;
		color: var(--primary-blue);
	}
	&__bottom{
		position: absolute;
		width: 100%;
		top: 56px;
		opacity: 0;
		pointer-events:none;
		transform:scale(1) translateY(-56px);
		transition:.15s;
		background-color: #fff;
		z-index: 2;
		border-left:1px solid transparent;
		border-right:1px solid transparent;
		border-bottom:1px solid transparent;
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
		overflow: hidden;
		&__items{
			position: relative;
			
			ul{
				display: flex;
				flex-direction: column;
			}
			li{
				position: relative;
				flex-shrink: 0;
				min-height:48px;
				border-bottom: 1px solid #e8e8e8;
				> a,
				> span{
					padding-left: 18px;
					padding-right: 18px;
					color: var(--primary-blue);
					display: block;
					white-space: nowrap;
					@include text-crop();
					cursor: pointer;
					display: flex;
					align-items:center;
					line-height:48px;
					&:hover{
					    color: var(--primary-orange);
					}
					
				}
				&.has-submenu{
					> span{
						background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8px' height='12px'%3E%3Cpath fill-rule='evenodd' fill='%230c2f72' d='M0.455,1.398 L4.833,5.984 L0.455,10.571 L1.803,11.980 L7.538,5.984 L1.803,-0.011 L0.455,1.398 Z'/%3E%3C/svg%3E");
						background-repeat: no-repeat;
						background-position: right 18px center;
						padding-right: 30px;
						&:hover{
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='8px' height='12px'%3E%3Cpath fill-rule='evenodd' fill='%23fd9927' d='M0.455,1.398 L4.833,5.984 L0.455,10.571 L1.803,11.980 L7.538,5.984 L1.803,-0.011 L0.455,1.398 Z'/%3E%3C/svg%3E");
						}
					}
					
					
					
					&.submenu-active{
						.submenu{
							left: 0;
						}
						
					}
				}
				
			}
			.main-menu{
			    
			}
			.submenu{
				position: absolute;
				width: 100%;
				//height: 100%;
				z-index: 1;
				left: 100%;
				top: 0;
				background-color: #fff;
				li{
					
				}
			}
			
			.go-back{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='12px' style='transform:scale(-1)'%3E%3Cpath fill-rule='evenodd' fill='%230c2f72' d='M6.000,0.000 L5.125,0.874 L9.635,5.382 L0.000,5.382 L0.000,6.619 L9.635,6.619 L5.125,11.126 L6.000,12.000 L12.000,6.000 L6.000,0.000 Z'/%3E%3C/svg%3E");
				padding-left: 40px;
				background-position: left 18px center;
				background-repeat: no-repeat;
				color: var(--primary-blue);
				&:hover{
					color: var(--primary-orange);
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='12px' style='transform:scale(-1)'%3E%3Cpath fill-rule='evenodd' fill='%23fd9927' d='M6.000,0.000 L5.125,0.874 L9.635,5.382 L0.000,5.382 L0.000,6.619 L9.635,6.619 L5.125,11.126 L6.000,12.000 L12.000,6.000 L6.000,0.000 Z'/%3E%3C/svg%3E");
				}
			}
			.logout{
				padding-left: 18px;
				padding-right: 18px;
			}
			
		}
		
		
		&__logout{
			padding-top: 9px;
			padding-bottom: 9px;
			padding-left: 18px;
			&__link{
				padding-left: 22px;
				color:var(--primary-blue);
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14px' height='14px'%3E%3Cpath fill-rule='evenodd' fill='%230c2f72' d='M10.750,4.000 L9.692,5.058 L10.878,6.250 L4.750,6.250 L4.750,7.750 L10.878,7.750 L9.692,8.935 L10.750,10.000 L13.750,7.000 L10.750,4.000 ZM1.750,1.750 L7.000,1.750 L7.000,0.250 L1.750,0.250 C0.925,0.250 0.250,0.925 0.250,1.750 L0.250,12.250 C0.250,13.075 0.925,13.750 1.750,13.750 L7.000,13.750 L7.000,12.250 L1.750,12.250 L1.750,1.750 Z'/%3E%3C/svg%3E");
				background-position: left center; 
				display: block;
				background-repeat: no-repeat;
				@include text-crop();
				&:hover{
				    color:var(--primary-orange);
				    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='14px' height='14px'%3E%3Cpath fill-rule='evenodd' fill='%23fd9927' d='M10.750,4.000 L9.692,5.058 L10.878,6.250 L4.750,6.250 L4.750,7.750 L10.878,7.750 L9.692,8.935 L10.750,10.000 L13.750,7.000 L10.750,4.000 ZM1.750,1.750 L7.000,1.750 L7.000,0.250 L1.750,0.250 C0.925,0.250 0.250,0.925 0.250,1.750 L0.250,12.250 C0.250,13.075 0.925,13.750 1.750,13.750 L7.000,13.750 L7.000,12.250 L1.750,12.250 L1.750,1.750 Z'/%3E%3C/svg%3E");
				}
			}
		}
		
	}
	
	&:hover,
	&--active{
		.user-dropdown__top{
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='8px'%3E%3Cpath fill-rule='evenodd' fill-opacity='0.8' fill='rgb(14, 35, 60)' opacity='1' d='M10.590,0.437 L6.000,4.818 L1.410,0.437 L0.000,1.786 L6.000,7.524 L12.000,1.786 L10.590,0.437 Z'/%3E%3C/svg%3E");
		}
		.icon--user,
		.user-dropdown__name{
			opacity: 1;
		}
		.user-dropdown__bottom{
		
		}
	}
	&--active{
		border-color:#e8e8e8;
		.user-dropdown__bottom{
			opacity: 1;
			transform:scale(1) translateY(0);
			pointer-events:auto;
		}	
		.user-dropdown__top,
		.user-dropdown__bottom__items,
		.user-dropdown__bottom{
			border-color:#e8e8e8;
		}
		.user-dropdown__top{
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='8px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' d='M10.590,7.563 L6.000,3.182 L1.410,7.563 L0.000,6.214 L6.000,0.476 L12.000,6.214 L10.590,7.563 Z'/%3E%3C/svg%3E");
		}
	}
}

@media screen and (max-width:1280px){

}

@media screen and (max-width:1024px){
	
	
	
	
}

@media screen and (max-width:1023px){

}

@media screen and (max-width:600px){
	.user-dropdown{
		min-width: 64px;
		.user-dropdown__name{
			display: none;
		}
		.icon--user{
		}
		&__top{
			padding-right: 0;
			background-position: right center;
		}
	}
	.topbar__user{
		flex-grow: 0;
		margin-right: 0;
	}
	.user-dropdown--active .user-dropdown__top, .user-dropdown--active .user-dropdown__bottom__items, .user-dropdown--active .user-dropdown__bottom{
		border-color: transparent;
	}
	.user-dropdown__bottom{
		position: fixed;
		top: 66px;
		left: 0;
		right: 0;
	}
}

@media screen and (max-width:480px){
}