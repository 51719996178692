:root{
	--navbar-height: 66px;
	--primary-blue: #0c2f72;
	--primary-orange: #fd9927;
}

$min_width: 320px;
$max_width: 1366px;


@media screen and (max-width:600px){
	:root{
		--navbar-height: 66px;
	}
	
}