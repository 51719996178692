.list{
	border: 1px solid #ecf3fe;
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	overflow: hidden;
	background-color: #fff;
	box-shadow: 0px 0px 36px 0px rgba(70, 136, 241, 0.1);
	&__top{
		padding: 18px;
		border-bottom: 1px solid #ecf3fe;
	}
	&__center{
	
	}
	&__bottom{
	
	}
	
	&__filters{
		display: flex;
		flex-wrap:wrap;
		align-items:center;
		margin-left: -9px;
		margin-right: -9px;
		justify-content: space-between;
		
	}
	&__filter{
		padding-left: 9px;
		padding-right: 9px;
		
		&--sort{
			flex-basis:300px;
		}
	}
	
	&__items{
		display: flex;
		flex-direction: column;
	}
	&__item{
		display: flex;
		justify-content: space-between;
		align-items: flex-start ;
		border-bottom: 1px solid #ecf3fe;
		padding: 18px;
		
		position: relative;
		color: #4688f1;
		&:hover:not(.list__item--title){
			cursor: pointer;
			background-color: #ecf3fe;
			color: #1349e4;
		}
		&--title{
			color: #003082;
		}
		&:last-child{
			border-bottom: 0;
		}
		
		&:focus,
		&--selected{
			color: #0e233c;
			background-color: #fff;
			&:before{
				content:'';
				width: 4px;
				left: 0;
				top: 0;
				bottom: 0;
				background-color: #0e233c;
				position: absolute;
			}
			.list__item__title{
				font-weight: 700;
			}
		}
		
		&__left{
			display: flex;
			align-items:center;
			align-items:flex-start;
			@include text-crop($line-height:1.5, $top-adjustment: 1px ,$bottom-adjustment:-4px);
			padding-right: 18px;
		}
		&__right{
		
		}
		&__number{
			padding-right: 18px;
			flex-shrink: 0;
			flex-grow:0;
			width: 36px;
		}
		&__name{
		
		}
		&__progress{
			width: 24px;
			height: 24px;
			
			background-repeat: no-repeat;
			display: block;
			position: relative;
			&:after{
				content:'';
				position: absolute;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				border:2px solid #9baece;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background-position: center right;
				background-repeat: no-repeat;
			}
			.list__item--finished & {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18px' height='18px'%3E%3Cpath fill-rule='evenodd' fill='rgb(98, 181, 50)' d='M18.000,2.862 L7.731,13.140 L3.915,9.324 L5.184,8.055 L7.731,10.602 L16.731,1.602 L18.000,2.862 ZM16.011,7.398 C16.128,7.911 16.200,8.451 16.200,9.000 C16.200,12.978 12.978,16.200 9.000,16.200 C5.022,16.200 1.800,12.978 1.800,9.000 C1.800,5.022 5.022,1.800 9.000,1.800 C10.422,1.800 11.736,2.214 12.852,2.925 L14.148,1.629 C12.690,0.603 10.917,0.000 9.000,0.000 C4.032,0.000 -0.000,4.032 -0.000,9.000 C-0.000,13.968 4.032,18.000 9.000,18.000 C13.968,18.000 18.000,13.968 18.000,9.000 C18.000,7.929 17.802,6.903 17.460,5.949 L16.011,7.398 Z'/%3E%3C/svg%3E");
				
				&:after{
					display: none;
				}
			}
		}
		
		&--finished{
			color: #62b532;
		}
	}
	
	
}