.overlay{
	position: fixed;
	z-index: 999;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(#0e233c,0);
	transition:.15s;
	transition:.35s;
	pointer-events:none;
	&--visible{
		background-color: rgba(#0e233c,0.25);
		pointer-events:auto;
		//transition-delay: 0s;
		cursor:pointer;
		.overlay__inner{
			transform:translateX(0);	
			//transition-delay: 0.35s;
			cursor:auto;
		}
	}
	&--background{
		.overlay__inner{
			//max-width:100%;
			//margin-right: auto;
			//margin-left: 0;
		    transform: translateX(-640px);
			
			transform-origin: left;
			
				
		}
	}
	&--secondary{
		z-index: 1000;
		.overlay__inner{
			//max-width:640px;
			
			&--wide{
				//max-width:800px; 
			}
		}
	}
	&__inner{
		width: 100%;
		background-color: #fff;
		margin-left: auto;
		max-width: 1000px;
		overflow-y: auto;
		height: calc(var(--vh, 1vh) * 100);
		transform:translateX(100%);
		transition:transform linear .25s;
		transition:.25s;
		transition:.35s;
		transition-delay: 0s;
		will-change:transform;
		-webkit-overflow-scrolling: touch;
		overscroll-behavior: contain;
		display: flex;
    	flex-direction: column;
    	//box-shadow: 0px 0px 36px 0px rgba(#0e233c,0.1); 
		&::-webkit-scrollbar {
		  width: 5px;
		}
		 
		&::-webkit-scrollbar-track {
		  background: #ffffff;
		}
		 
		&::-webkit-scrollbar-thumb {
		  background: var(--primary-orange); 
		}
	}
	&__content{
		
	}
	&__divider{
		margin-left: -36px;
		margin-right: -36px;
		display: flex;
		height: 1px;
		background-color:#e8e8e8;
		
	}
	
	&__buttons{
		display: flex;
		flex-wrap:wrap;
		margin-left: -9px;
		margin-right: -9px;
		margin-bottom: -18px !important;
		padding-top: 36px;
		padding-bottom: 36px;
		.button{
			margin-left: 9px;
			margin-right: 9px;
			width: calc(25% - 18px);
			margin-bottom: 18px;
		}
	}
	
	.blue-boxes{
		padding-top: 36px;
		padding-bottom: 36px;
		background-color: #e8e8e8;
		padding-left: 36px;
		padding-right: 36px;
		margin-left: -36px;
		margin-right: -36px;
		
		.blue-box{
			background-color: #dae8fd;
		}
	}
	
	.cards{
		margin-bottom: 36px;
	}
	
	.read-more-toggle{
		margin-bottom: 36px;
	}
	.table-layout-01{
		//margin-bottom: 36px;
	}
	
	.text-divider{
		margin-left: -36px;
		margin-right: -36px;
	}
	.notes{
		margin-bottom: 36px;
	}
	p{
		+.text-divider{
			margin-top: 24px;
		}
	}
	
	&__top,
	&__center,
	&__footer{
		//max-width: 923px;
		margin-right: auto;
		width: 100%;
	}
	
	&__top{
		&:not(:empty){
			position: sticky;
			top: 0;
			background-color: #fff;
			padding-left: 18px;
			padding-right: 18px;
			border-bottom:1px solid #e8e8e8;
			flex-shrink:0;
			display: flex;
			flex-direction: column;
			max-width: 1000px;
			.close-link{
				+ .tabs{ 
					margin-top: 9px;
				}
			}
			.tabs{
				align-self:flex-start;
				border-bottom: 0;
				margin-bottom: -18px;
			}
		}
	}
	&__center{
		padding: 18px;
		padding-bottom: 0;
		
		>*:first-child{
			margin-top: 0;
		}
		>*:last-child{
			margin-bottom: 18px;
		}
		
		&:not([data-overlay-tab="information"]){
			display: none;
		}
		&:not([data-overlay-tab]){
			display: block;
			
			width: 100%;
		}
		
		.form{
			margin-top: 0;
		}
	}
	&__footer{
		position: sticky;
		bottom: 0;
		background-color: #fff;
		padding: 18px;
		border-top:1px solid #e8e8e8;
		margin-top: auto;
		//max-width: 1000px;
	}
}


@media screen and (max-width:991px){	
	.overlay__buttons .button {
    	width: calc(50% - 18px);   
	}
	.overlay__inner{
		
		max-width: 100%;
		overflow-x: hidden;
		box-shadow:none;
	}
}

@media screen and (max-width:767px){
	.overlay{
		.card--horizontal{
			width: calc(100% - 9px);
		}
	}	
}

@media screen and (max-width:480px){
	
	
	.overlay__inner{
		
	}
}

@media screen and (max-width:374px){
	.overlay__buttons .button {
    	width: calc(100% - 18px);
	}
}