html,
body,
*{
	
}

html{
	padding: env(safe-area-inset); 
	font-family: 'Frutiger LT Std';
	background-color: #f4f4f4;
	font-weight: 300;
	color: #212121;
	font-size: 14px;
}
body{
	
}

.outer-wrapper{
	display: flex;
	//min-height: calc((var(--vh, 1vh) * 100));
    min-height: 100vh;
	width: 100%;
	flex-direction: column;
	.inner-wrapper{
		flex-grow:1;
		flex-shrink: 1;
		display: flex;
    	flex-direction: column;
    	max-width: 100%;
	}
	
	.side-collapsed &{
		//padding-left: 72px;
	}
}

.main{
	display: flex;
    flex-grow: 1;
	&__left{
		flex-grow:0;
		flex-shrink: 0;
		display: flex;
	}
	&__right{
		flex-grow:1;
		flex-shrink: 1;
		// height: calc(calc(var(--vh, 1vh) * 100) - var(--navbar-height));
		// overflow-y: auto;
		// -webkit-overflow-scrolling: touch;
		// overscroll-behavior: contain; 
		&::-webkit-scrollbar {
		  width: 5px;
		}
		 
		&::-webkit-scrollbar-track {
		  background: #ffffff;
		}
		 
		&::-webkit-scrollbar-thumb {
		  background: #ecf3fe; 
		}
	}
}

.pwa {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    font-size: 12px;
    transform: translateY(100%);
    transition: .3s;
    z-index: 3;

    &--visible {
        transform: translateY(0);
    }

    &__message {
        padding-left: 15px;

        &__title {
            font-size: 16px;
        }

        &__content {
            span.icon {
                width: 12px;
                height: 18px;
                // background-image: url(../../action-icon-ios7.png);
                -webkit-background-size: contain;
                background-size: contain;
                background-repeat: no-repeat;
                display: inline-block;
                margin-left: 3px;
                margin-right: 3px;
                position: relative;
                top: 2px;
            }
        }
    }

    &__close {
        cursor: pointer;
    }

}

.footer{
	color: #D8D8D8;
	text-align: center;
	background-color: #fff;
	border-top:#D8D8D8;
	font-size: 12px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items:center;
	height: 36px;
	flex-shrink: 0;

    /*
    background-color: #fff;
    border-top: 1px solid #e8e8e8;
    // height: var(--navbar-height);
    position: absolute;
    bottom: 0;
    z-index: 99;
    width:100%;
	color: #D8D8D8;
	text-align: center;
	font-size: 12px;
	height: 24px;
    padding-top: 4px;
	display: flex;
	justify-content: center;
	align-items:center;
	flex-shrink: 0;
    //*/
}

@media (prefers-reduced-motion) {
  *{
  	transition: none !important;
  }
}
@media screen and (max-width:1180px){
	.outer-wrapper{
		//padding-left: 72px;
		.inner-wrapper{
			
		}
		
		.side-collapsed &{
			
		}
	}
}

@media screen and (max-width:1024px){
	.main__left{
		position: fixed;
		z-index: 2;
	    transform: translateX(100%);
	    transition: .15s;
	    right: 0;
	    width: 100%;
	    
	    &--visible{
	    	transform: translateX(0);
	    	z-index: 101;
	    }
	}
}

@media screen and (max-width:600px){
	.outer-wrapper{
		padding-left: 0;
	}
	.side{
		&.side--collapsed{
			transform: translateX(-100%);
		}	
	}
}

.spacer {
    float:none;
    height:15px;

    &--line {
        margin-top: 15px;
        border-bottom:1px #ccc solid;
    }
}