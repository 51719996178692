.components {
    padding-top: 18px;
    //padding-bottom: 36px;

    .component {
        margin-bottom: 18px;

        +.component {
            //margin-top: 36px;
            //margin-top: -18px;

            &:last-child {
                //margin-bottom: 0;
            }
        }
    }

    +.components {
        //padding-top: 0;
    }

    &--header {}

    &__top {
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 18px;
        padding-left: 18px;
        padding-right: 18px;
        margin-left: -18px;
        margin-right: -18px;
    }

    &.tabs{
        padding-top:0px;
    }
}

.outline-box {
    border-radius: 6px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    padding: 36px;
    padding: 18px;
    box-shadow: 0px 4px 6px 0px rgba(33, 33, 33, 0.05);
    
    p {
        padding:5px;
    }

    +.outline-box {
        margin-top: 9px;
    }
    .container {
        padding-left:0px;
        padding-right:0px;

        &.centred {
            padding-top: 6px;
        }
        .col-12 {
            padding-left:2px;
            padding-right:2px;
        }
    }

    &--spacing-sm {
        padding: 18px;
        box-shadow: 0px 0px 18px 0px rgba(70, 136, 241, 0.1);
    }

    &--components {
        padding: 0;
    }

    &--grey {
        border-color: #ddd;
        background-color: #f2f2f2;
    }
    &--error {
		background-color: #ffe6eb;
        color: #e3022f;
        border-color:#fc0e3d;
	}
    &--warning {
		background-color: #ffefce;
        color: #c26c0c;
        border-color: #fd9927;
	}
    &--info {
		background-color: #e6ecfe;
        color:#0837ce;
        border-color: #b6c8ff; //#0c2f72;
	}
    &--success{
		background-color: #ebfaeb;
        color:#0f3d0f;
        border-color: #adebad;
	}
	&--inner{
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 18px;
        border: 0;
    }

    &__inner {
        display: flex;
        align-items: flex-start;
    }

    &__title {
        @include typography(18px, 18px, 18px, 1.5);
        @include text-crop($line-height: 1.5, $top-adjustment: 2px, $bottom-adjustment: -15px);
        font-weight: bold;
        margin-top: 0 !important;
        display: block;
    }

    &__icon {
        margin-right: 6px;
        flex-shrink: 0;
        flex-grow: 0;

        img,
        svg {
            display: block;
            width: 36px;
            height: 36px;
        }
    }

    &__content {
        span {
            @include text-crop($line-height: 1.5, $top-adjustment: 7px, $bottom-adjustment: -6px);
            display: block;
        }
    }

    &__top {
        display: flex;
        flex-direction: column;
        padding: 36px;
        border-bottom: 1px solid #e8e8e8;
    }

    &__center {
        padding: 36px;
    }

    &__divider {
        margin-left: -18px;
        margin-right: -18px;
        height: 1px;
        background-color: #e8e8e8;
        display: flex;
        margin-top: 6px;
        margin-bottom: 6px;

        &--spacing {
            margin-top: 18px;
            margin-bottom: 18px;
        }
    }

    .outline-box {
        box-shadow: none;

        +.button {
            margin-top: 18px;
        }
    }

    .box-header {
        padding-bottom: 15px;
    }

    &background-text {
        color: #999999;
        font-style: italic;
    }
}

.icon-title {
    display: flex;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #003082;

    &:before {
        width: 36px;
        height: 36px;
        content: '';
        left: 0;
        top: 0;
        border-radius: 50%;
        margin-right: 9px;
        background-position: center center;
        background-repeat: no-repeat;
        flex-shrink: 0;
    }
}

.iframe {
    position: relative;

    iframe,
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: 4px;
        overflow: hidden;
        object-fit: cover;
    }

    &--maps {
        aspect-ratio: 762 / 240;
    }
}

.page-title {
    padding-top: 0px;
    padding-bottom: 18px;

    h3 {
        padding-right: 6px;
    }

    .page-filters {
        padding:0;
        button {
            &.button--icon {
                justify-content: center;
            }
        }
    }
}

.details-row {
    display: flex;
    justify-content: space-between;
    margin-left: -9px;
    margin-right: -9px;

    +.details-row {
        margin-top: 18px;
    }

    +.outline-box {
        margin-top: 18px;
    }

    &--label-line {
        position: relative;

        .details-row__column {
            position: relative;
            z-index: 1;

            &:nth-child(1) {

                .label,
                .value {
                    padding-right: 16px;
                    background-color: #fff;
                    @include text-crop();
                }

                .label {
                    +.value {
                        margin-top: 6px;
                    }
                }
            }

            &:nth-child(2) {

                .label,
                .value {
                    padding-left: 16px;
                    background-color: #fff;
                    @include text-crop();
                }

                .label {
                    +.value {
                        margin-top: 6px;
                    }
                }
            }
        }

        &:after {
            position: absolute;
            top: 5px;
            left: 9px;
            right: 9px;
            content: '';
            height: 1px;
            background-color: #e8e8e8;
        }

        &--vertical {
            flex-direction: column;

            .details-row__column {
                position: relative;
                z-index: 1;

                &:nth-child(1) {

                    .label,
                    .value {
                        margin-bottom: 6px;
                        position: relative;
                        display: inline-block;
                        width: auto;
                        background-color: #fff;
                        z-index: 3;
                        top: -6px;
                        margin-bottom: 0;
                    }

                    &:after {
                        position: absolute;
                        top: 3px;
                        left: 9px;
                        right: 9px;
                        content: '';
                        height: 1px;
                        background-color: #e8e8e8;
                    }
                }

                &:nth-child(2) {

                    .label,
                    .value {
                        text-align: left;
                        padding-left: 0;
                    }
                }
            }

            &:after {
                display: none;
            }

        }

    }

    &__column {
        //min-width:100px;
        padding-left: 9px;
        padding-right: 9px;

        &:nth-child(2) {
            text-align: right;
        }
    }

    button {
        margin-top: 12px;
        margin-right:0px;
    }
}

.outline-box--grey .details-row.details-row--label-line {
    .details-row__column {
        &:nth-child(1) {
            .label,
            .value {
                background-color: #f2f2f2;
            }
        }
        &:nth-child(2) {
            .label,
            .value {
                background-color: #f2f2f2;
            }
        }
    }
}

.label {
    font-size: 12px;
    @include text-crop();
    color: #7a7a7a;
}

.value {
    font-weight: normal;
    line-height: 1.5;
}

.selected-filters {
    background-color: #f4f4f4;
    display: flex;
    margin-left: -18px;
    margin-right: -18px;
    flex-wrap: wrap;
    padding: 18px;
    padding-bottom: 9px;
    border-bottom: 1px solid #e8e8e8;

    .overlay & {
        margin-top: -18px;
        margin-bottom: 18px;
    }

}

.selected-filter {
    margin-bottom: 9px;
    margin-right: 9px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding-left: 12px;
    padding-right: 30px;
    height: 24px;
    text-transform: uppercase;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='10px' height='10px'%3E%3Cpath fill-rule='evenodd' fill='rgb(122, 122, 122)' d='M10.000,1.007 L8.993,0.000 L5.000,3.993 L1.007,0.000 L0.000,1.007 L3.993,5.000 L0.000,8.993 L1.007,10.000 L5.000,6.007 L8.993,10.000 L10.000,8.993 L6.007,5.000 L10.000,1.007 Z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid #d3d3d3;
    font-size: 12px;
    font-weight: normal;
}

.invoice {
    border-radius: 6px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    box-shadow: 0px 4px 6px 0px rgba(33, 33, 33, 0.05);

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e8e8e8;
    }

    &__body {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e8e8e8;

        &:last-child {
            border-bottom-color: transparent;
        }
    }

    &__label,
    &__value {
        padding: 18px;
    }

    &__value {
        font-weight: normal;
    }

    &__footer {
        border-radius: 6px;
        background-color: #3d598e;
        overflow: hidden;

        .invoice__row {
            border-bottom: 0;
            justify-content: flex-end;
        }

        .label {
            color: #fff;
        }

        .invoice__value {
            color: #ffffff;
        }

        &__top {
            padding-top: 12px;
            padding-bottom: 12px;

            .invoice__label,
            .invoice__value {
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }

        &__bottom {
            background-color: #0c2f72;

            .invoice__value {
                color: rgba(#fff, 1);
                font-weight: bold;
            }
        }
    }

    &__button {
        padding: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.transport-stop-details {

    width: 100%;

    .item-details {
        padding-top: 18px;
    }

    .outline-box--details {
        .details-link {
            padding-bottom: 0px;
            margin-bottom: 15px;

            &.details-link--icon {
                background-position-y: 0px;
                background-position-x: 0px;
            }
            &.details-link--closed {
                background-position-y: 0px;
                background-position-x: 0px;
                margin-bottom: 0px;
            }
        }
    }
}

.requests-list {

    border-radius: 6px;
    border:1px solid #e8e8e8;
    padding: 5px 15px;
    box-shadow: 0px 4px 6px 0px rgba(33, 33, 33, 0.05);

    &--active {
        border-radius: 6px;
        border:1px solid #e8e8e8;
        padding: 5px 15px;
        box-shadow: 0px 4px 6px 0px rgba(33, 33, 33, 0.05);
        background-color: #fafafa;

        &:hover {
            background-color: #e8effd;
        }
    }
    &--disabled {
        border:none;
        box-shadow:none;
    }

    .item-title {
        color: #0c2f72;
        font-weight:bold;
        padding: 8px 0px;
        
        &.details-link {
            padding: 8px 0px;
            margin:0;
        }
    }

    .request-item {
        padding-bottom: 5px;

        .item-header {
            color:#999;
            font-style:italic;
            margin-top: -5px;
        }
        .item-value {
            margin-bottom:10px;
        }
    }
}

.article-list-plain {
    .requests-list {
        border-radius: 6px;
        border:none;
        padding: 0;
        box-shadow: none;
    }
}

.page-header {
    .components {
        .component {
            margin-bottom: 0px;
        }
    }
}

@media screen and (min-width:1100px){}

@media screen and (max-width:991px){}

@media screen and (min-width:768px){
	.outline-boxes{
		display: flex;
		flex-wrap:wrap;
		margin-left: -9px;
		margin-right: -9px;
		.component{
			width: 50%;
			padding-left: 9px;
			padding-right: 9px;
		}
	}
    .page-title {
        padding-top:18px;
    }
}

@media screen and (max-width:480px){}

@media screen and (max-width:320px){
    h3 {
        line-height: 1.2;
        padding-bottom: 18px;
    }
}