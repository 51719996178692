.icon{
	width: 24px;
	height: 24px;
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	&--notification{
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' d='M12.000,12.001 L12.000,15.001 L4.000,15.001 L4.000,8.001 C4.000,5.791 5.790,4.001 8.000,4.001 C8.850,4.001 9.640,4.261 10.280,4.721 L11.710,3.291 C11.070,2.781 10.320,2.411 9.500,2.201 L9.500,1.501 C9.500,0.671 8.830,0.001 8.000,0.001 C7.170,0.001 6.500,0.671 6.500,1.501 L6.500,2.201 C3.910,2.861 2.000,5.211 2.000,8.001 L2.000,15.001 L0.000,15.001 L0.000,17.001 L16.000,17.001 L16.000,15.001 L14.000,15.001 L14.000,12.001 L12.000,12.001 ZM8.000,20.001 C9.100,20.001 10.000,19.101 10.000,18.001 L6.000,18.001 C6.000,19.101 6.900,20.001 8.000,20.001 Z'/%3E%3C/svg%3E");
		
		&--unread{
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' d='M12.000,12.001 L12.000,15.001 L4.000,15.001 L4.000,8.001 C4.000,5.791 5.790,4.001 8.000,4.001 C8.850,4.001 9.640,4.261 10.280,4.721 L11.710,3.291 C11.070,2.781 10.320,2.411 9.500,2.201 L9.500,1.501 C9.500,0.671 8.830,0.001 8.000,0.001 C7.170,0.001 6.500,0.671 6.500,1.501 L6.500,2.201 C3.910,2.861 2.000,5.211 2.000,8.001 L2.000,15.001 L0.000,15.001 L0.000,17.001 L16.000,17.001 L16.000,15.001 L14.000,15.001 L14.000,12.001 L12.000,12.001 ZM8.000,20.001 C9.100,20.001 10.000,19.101 10.000,18.001 L6.000,18.001 C6.000,19.101 6.900,20.001 8.000,20.001 Z'/%3E%3Cpath fill-rule='evenodd' fill='rgb(252, 14, 61)' d='M19.998,7.000 C19.998,9.209 18.207,11.000 15.998,11.000 C13.789,11.000 11.999,9.209 11.999,7.000 C11.999,4.791 13.789,3.000 15.998,3.000 C18.207,3.000 19.998,4.791 19.998,7.000 Z'/%3E%3C/svg%3E");
		}
	}
	
	&--help{
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' d='M9.000,16.000 L11.000,16.000 L11.000,14.000 L9.000,14.000 L9.000,16.000 ZM10.000,-0.000 C4.480,-0.000 0.000,4.480 0.000,10.000 C0.000,15.520 4.480,20.000 10.000,20.000 C15.520,20.000 20.000,15.520 20.000,10.000 C20.000,4.480 15.520,-0.000 10.000,-0.000 ZM10.000,18.000 C5.590,18.000 2.000,14.410 2.000,10.000 C2.000,5.590 5.590,2.000 10.000,2.000 C14.410,2.000 18.000,5.590 18.000,10.000 C18.000,14.410 14.410,18.000 10.000,18.000 ZM10.000,4.000 C7.790,4.000 6.000,5.790 6.000,8.000 L8.000,8.000 C8.000,6.900 8.900,6.000 10.000,6.000 C11.100,6.000 12.000,6.900 12.000,8.000 C12.000,10.000 9.000,9.750 9.000,13.000 L11.000,13.000 C11.000,10.750 14.000,10.500 14.000,8.000 C14.000,5.790 12.210,4.000 10.000,4.000 Z'/%3E%3C/svg%3E");
	}
	&--user{
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='20px'%3E%3Cpath fill-rule='evenodd' fill='rgb(14, 35, 60)' d='M10.000,-0.000 C4.480,-0.000 -0.000,4.480 -0.000,10.000 C-0.000,15.520 4.480,20.000 10.000,20.000 C15.520,20.000 20.000,15.520 20.000,10.000 C20.000,4.480 15.520,-0.000 10.000,-0.000 ZM5.070,16.280 C5.500,15.380 8.120,14.500 10.000,14.500 C11.880,14.500 14.510,15.380 14.930,16.280 C13.570,17.360 11.860,18.000 10.000,18.000 C8.140,18.000 6.430,17.360 5.070,16.280 ZM16.360,14.830 C14.930,13.090 11.460,12.500 10.000,12.500 C8.540,12.500 5.070,13.090 3.640,14.830 C2.620,13.490 2.000,11.820 2.000,10.000 C2.000,5.590 5.590,2.000 10.000,2.000 C14.410,2.000 18.000,5.590 18.000,10.000 C18.000,11.820 17.380,13.490 16.360,14.830 ZM10.000,4.000 C8.060,4.000 6.500,5.560 6.500,7.500 C6.500,9.440 8.060,11.000 10.000,11.000 C11.940,11.000 13.500,9.440 13.500,7.500 C13.500,5.560 11.940,4.000 10.000,4.000 ZM10.000,9.000 C9.170,9.000 8.500,8.330 8.500,7.500 C8.500,6.670 9.170,6.000 10.000,6.000 C10.830,6.000 11.500,6.670 11.500,7.500 C11.500,8.330 10.830,9.000 10.000,9.000 Z'/%3E%3C/svg%3E");
	}
	&--email{
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='16px'%3E%3Cpath fill-rule='evenodd' fill='rgb(70, 136, 241)' d='M20.000,2.000 C20.000,0.900 19.100,-0.000 18.000,-0.000 L2.000,-0.000 C0.900,-0.000 0.000,0.900 0.000,2.000 L0.000,14.000 C0.000,15.100 0.900,16.000 2.000,16.000 L18.000,16.000 C19.100,16.000 20.000,15.100 20.000,14.000 L20.000,2.000 ZM18.000,2.000 L10.000,7.000 L2.000,2.000 L18.000,2.000 ZM18.000,14.000 L2.000,14.000 L2.000,4.000 L10.000,9.000 L18.000,4.000 L18.000,14.000 Z'/%3E%3C/svg%3E");
		
		&:hover{
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20px' height='16px'%3E%3Cpath fill-rule='evenodd' fill='%231349e4' d='M20.000,2.000 C20.000,0.900 19.100,-0.000 18.000,-0.000 L2.000,-0.000 C0.900,-0.000 0.000,0.900 0.000,2.000 L0.000,14.000 C0.000,15.100 0.900,16.000 2.000,16.000 L18.000,16.000 C19.100,16.000 20.000,15.100 20.000,14.000 L20.000,2.000 ZM18.000,2.000 L10.000,7.000 L2.000,2.000 L18.000,2.000 ZM18.000,14.000 L2.000,14.000 L2.000,4.000 L10.000,9.000 L18.000,4.000 L18.000,14.000 Z'/%3E%3C/svg%3E");
		}
	}
}