.divider{
	display: flex;	
	align-items:center;
	&:before,
	&:after{
		content:'';
		height: 1px;
		background-color: #dae7fc;
		top: 50%;
		transform:translateY(-50%);
		position: relative;
	}
	&:before{
		flex-grow:0;
		flex-shrink: 0;
		flex-basis:18px;
	}
	&:after{
		flex-grow:1;
		flex-shrink: 1;
		flex-basis:18px;
	}
	h1,h2,h3,h4{
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		
	}
	
	> span{
		display: flex;
		align-items:center;
		padding-left: 18px;
		padding-right: 18px;
	}
	
	.total{
		margin-left: 9px;
		flex-shrink: 0;
		flex-grow:0;
	}
}