.page-navigation{
	width: 332px;
	background-color: #fff;
	border-right:1px solid #ecf3fe;
	display: block;
	flex-grow:1;
	display: flex;
	flex-direction: column;
	//height: calc(100vh - var(--navbar-height)); 
	height: calc(calc(var(--vh, 1vh) * 100) - var(--navbar-height));
	position: relative;
	&__top{
		padding-top: 36px;
		padding-left: 18px;
		padding-right: 18px;
		padding-bottom: 18px;
		border-bottom:1px solid #ecf3fe;
		flex-shrink: 0;
	}
	&__center{
		flex-grow:1;
		flex-shrink:1;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		overscroll-behavior: contain; 
		&::-webkit-scrollbar {
		  width: 5px;
		}
		 
		&::-webkit-scrollbar-track {
		  background: #ffffff;
		}
		 
		&::-webkit-scrollbar-thumb {
		  background: #ecf3fe; 
		}
	}
	&__bottom{
	
	}
	&__previous{
		color: rgba(#0e233c,0.4);;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='6px' height='10px'%3E%3Cpath fill-rule='evenodd' opacity='0.4' fill='rgb(33, 33, 33)' d='M5.672,8.442 L2.387,5.000 L5.672,1.558 L4.661,0.500 L0.357,5.000 L4.661,9.500 L5.672,8.442 Z'/%3E%3C/svg%3E");
		background-position: left center;
		background-repeat: no-repeat;
		@include text-crop($line-height:1.5, $top-adjustment: 1px ,$bottom-adjustment:-6px);
		padding-left: 12px;
		display: block;
		font-size: 14px;
		+ .outline-box{
			margin-top: 18px;
		}
		&:hover{
			color: #0e233c;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='6px' height='10px'%3E%3Cpath fill-rule='evenodd' opacity='1' fill='rgb(33, 33, 33)' d='M5.672,8.442 L2.387,5.000 L5.672,1.558 L4.661,0.500 L0.357,5.000 L4.661,9.500 L5.672,8.442 Z'/%3E%3C/svg%3E");
		}
	}
	
	&__toggles{
		
	}
	&__toggle{
		border-bottom:1px solid #ecf3fe;
		padding-left: 18px;
		padding-right: 18px;
		&__top{
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='8px'%3E%3Cpath fill-rule='evenodd' fill-opacity='0.6' fill='rgb(0, 48, 130)' d='M10.590,0.437 L6.000,4.818 L1.410,0.437 L-0.000,1.786 L6.000,7.524 L12.000,1.786 L10.590,0.437 Z'/%3E%3C/svg%3E");
			cursor: pointer;
			background-repeat: no-repeat;
			background-position: right center;
			padding-top: 18px;
			padding-bottom: 18px;
			&:hover{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='8px'%3E%3Cpath fill-rule='evenodd' fill-opacity='1' fill='rgb(0, 48, 130)' d='M10.590,0.437 L6.000,4.818 L1.410,0.437 L-0.000,1.786 L6.000,7.524 L12.000,1.786 L10.590,0.437 Z'/%3E%3C/svg%3E");
			}
			
		}
		&__content{
			display: none;
			ul{
				li{
					
				}
			}
		}
		&--open{
			.page-navigation__toggle__top{
				font-weight: 700;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='8px'%3E%3Cpath fill-rule='evenodd' fill-opacity='0.6' fill='rgb(14, 35, 60)' d='M10.590,7.563 L6.000,3.182 L1.410,7.563 L-0.000,6.214 L6.000,0.476 L12.000,6.214 L10.590,7.563 Z'/%3E%3C/svg%3E");
				
				&:hover{
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='12px' height='8px'%3E%3Cpath fill-rule='evenodd' fill-opacity='1' fill='rgb(14, 35, 60)' d='M10.590,7.563 L6.000,3.182 L1.410,7.563 L-0.000,6.214 L6.000,0.476 L12.000,6.214 L10.590,7.563 Z'/%3E%3C/svg%3E");
				}
			}
			.page-navigation__toggle__content{
				display: block;
			}
		}
	}
	&__toggle-small{
		position: absolute;
		width: 36px;
		height: 36px;
		background-color: #003082;
		cursor: pointer;
		right: -36px;
		top: 0;
		color: #fff;
	}
}

.project-steps{
	&__step{
		display: flex;
		margin-bottom: 9px;
		height: 36px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		align-items:center;
		
		font-weight: 300;
		border:1px solid transparent;
		border-radius:4px;
		transition:.15s;
		padding-left: 12px;
		padding-right: 12px;
		display: flex;
		justify-content:space-between;
		cursor: pointer;
		
		
		&:hover{
			
			border-color:rgba(#4688f1,0.1);
			
			.project-steps__left span,
			.project-steps__progress{
				color: #1349e4;
			}
		}
		&--selected{
			background-color: #ecf3fe;
			border-color:#ecf3fe;
			color: #0e233c;
			
			.project-steps__left span{
				font-weight: bold;
				color: #0e233c;
			}
			.project-steps__progress{
				color: #0e233c;
			}
			&:hover{
				border-color:#ecf3fe;
			}
		}
		&--finished{
			.project-steps__progress{
				color: #62b532;
			}
		}
		&:last-child{
			.component &{
				margin-bottom: 0;
			}
			
		}
	}
	&__left{
		flex-grow:1;
		display: flex;
		align-items:center;
		span{
			color: #4688f1;
		}
		
		a{
			width: 100%;
		}
	}
	&__right{
		display: flex;
		justify-content: flex-end;
		align-items:center;
	}
	&__image{
		width: 24px;
		height: 24px;
		margin-right: 9px;
		border-radius:50%;
		overflow: hidden;
		img{
			width: 24px;
			height:24px;
			display: block;
			object-fit:cover;
			object-position:center center;
		}
	}
	&__progress{
		font-size: 14px;
		color: rgba(#0e233c,0.4);
		margin-right: 18px;
		text-align: right;
	}
	&__indicator{
		width: 18px;
		height: 18px;
		
		background-repeat: no-repeat;
		display: block;
		position: relative;
		&:after{
			content:'';
			position: absolute;
			width: 18px;
			height: 18px;
			border-radius: 50%;
			border:2px solid #9baece;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
		}
		.project-steps__step--finished & {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18px' height='18px'%3E%3Cpath fill-rule='evenodd' fill='rgb(98, 181, 50)' d='M18.000,2.862 L7.731,13.140 L3.915,9.324 L5.184,8.055 L7.731,10.602 L16.731,1.602 L18.000,2.862 ZM16.011,7.398 C16.128,7.911 16.200,8.451 16.200,9.000 C16.200,12.978 12.978,16.200 9.000,16.200 C5.022,16.200 1.800,12.978 1.800,9.000 C1.800,5.022 5.022,1.800 9.000,1.800 C10.422,1.800 11.736,2.214 12.852,2.925 L14.148,1.629 C12.690,0.603 10.917,0.000 9.000,0.000 C4.032,0.000 -0.000,4.032 -0.000,9.000 C-0.000,13.968 4.032,18.000 9.000,18.000 C13.968,18.000 18.000,13.968 18.000,9.000 C18.000,7.929 17.802,6.903 17.460,5.949 L16.011,7.398 Z'/%3E%3C/svg%3E");
			
			&:after{
				display: none;
			}
		}
	}
	&--partners{
		
	}
}

@media screen and (min-width:1025px){
	.page-navigation__toggle-small{
		display: none;
	}
}