.loader-background {
    background: #F7F7F7;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 185px; //66px;
    left:0px;
}

.loader {
    // background:#e8effd;
    // opacity: 0.5;
    padding-top: 122px;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 66px;
    left:0px;
    text-align: center;

    .loader-wrapper {
        margin-top: 24px;
        background:#fff;
        padding-left: 36px;
        padding-right: 36px;
        padding-top: 0px;
        padding-bottom: 0px;
        display:inline-block;
    }
    .loader-text {
        padding-top: 18px;
        padding-bottom: 18px;
        background: #fff;
        text-align: center;

        a {
            color:#3d598e;
        }
        a:hover {
            color:#003082;
        }
    }


    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-ellipsis div {
        position: absolute;
        top: 33px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #ccc;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    .lds-ellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(2) {
        left: 8px;
        animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(3) {
        left: 32px;
        animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(4) {
        left: 56px;
        animation: lds-ellipsis3 0.6s infinite;
    }
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}
.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;

    &.fast {
        animation-duration: 1s;
    }
    &.slow {
        animation-duration: 3.5s;
    }
}


@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fade-out{
     animation:fadeOutOpacity 0.5s 1;
    -webkit-animation:fadeOutOpacity 0.5s 1;
    animation-fill-mode: forwards;

    animation-delay:2s;
    -webkit-animation-delay:1s; /* Safari and Chrome */
    -webkit-animation-fill-mode: forwards;

} 

@keyframes fadeOutOpacity{
    from {opacity :1;}
    to {opacity :0;}
}

@-webkit-keyframes fadeOutOpacity{
    from {opacity :1;}
    to {opacity :0;}
}