.table-layout-01{
	table-layout: fixed;
	border:1px solid #ecf3fe;
	border-radius:8px;
	overflow: hidden;
	width: 100%;
	thead{
		th{
			font-weight: bold;
			color: #003082;
			padding-left: 18px;
			padding-right: 18px;
			height: 48px;
			text-align: left;
		}
	}
	tbody{
		td{
			padding-left: 18px;
			padding-right: 18px;
			height: 48px;
			text-align: left;
			border-top:1px solid #ecf3fe;
		}
	}
	
	.review{
		padding-top: 2px;
	}
}

.table-list{
	background-color: #fff;
	//table-layout: fixed;
	border-radius:8px;
	overflow: hidden;
	width: 100%;
	box-shadow: 0px 4px 6px 0px rgba(33, 33, 33, 0.05);
	thead{
		th{
            white-space: nowrap;
			font-weight: bold;
			color: var(--primary-blue);
			padding-left: 18px;
			padding-right: 18px;
			height: 48px;
			text-align: left;
			border-bottom:1px solid #ecf3fe;

            &.list-data-download {
                text-align:right;
            }
		}
	}
	tbody{
		td{
			padding-left: 18px;
			padding-right: 18px;
			height: 48px;
			text-align: left;
			border:0;

			&.arrow-right{
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16px' height='16px'%3E%3Cpath fill-rule='evenodd' fill='%230c2f72' d='M8.000,-0.000 L6.590,1.410 L12.170,7.000 L-0.000,7.000 L-0.000,9.000 L12.170,9.000 L6.590,14.590 L8.000,16.000 L16.000,8.000 L8.000,-0.000 Z'/%3E%3C/svg%3E");
				background-position: right 18px center;
				padding-right: 36px;
				background-repeat: no-repeat;
				cursor: pointer;
			}

            &.list-data-download {
                text-align:right;
            }

            &.list-data-checkbox {
                .form__element {
                    padding-top: 12px;
                    padding-bottom: 12px;
                    margin-bottom: 0px;
                }
                
            }
		}

		tr{
			&:nth-child(even){
				td{
					background-color: #f7f7f7;

				}
			}
		}
	}

    &.outline-box {

        &--grey{
            border-style:solid;
            border-width:1px;
            border-color: #ddd;
            background-color: #f2f2f2;
        }
    }
}